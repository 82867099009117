<script>

import L from 'leaflet'
import axios from "axios";
import 'leaflet.markercluster/dist/leaflet.markercluster.js'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'
import PolygonModal from "@/components/mapPage/polygon-modal.vue";
import OfferPriceModal from "@/components/offer-price-modal.vue";
import UIkit from "uikit";
import Icon from "@/components/icon.vue";
import MapFilter from "@/components/mapPage/map-filter.vue";
import {useFiltersStore} from "@/store/filter.js";

export default {
    name: "map-native",
    components: {Icon, PolygonModal, OfferPriceModal, MapFilter},
    setup() {
        const filtersStore = useFiltersStore()
        return {filtersStore}
    },
    data() {
        return {
            areas_count:0,
            map: null,
            //     INIT OPTIONS
            INIT_CENTER: [47.379433, 31.165579],
            INIT_ZOOM: 6,
            MAX_ZOOM: 18,
            MIN_ZOOM: 6,
            MAX_BOUNDS: [[44.387491, 22.137343], [52.369878, 40.228928]], //Установка максильного смещения карти
            SHOW_MARKER_ZOOM: 14,
            markers: null,
            markersCount: 0,
            currentZoom: 0,
            polygons: [],
            markersCenter: [],
            markersHidden: false,
            layerControl: null,
            firstLayer: null,
            secondLayer: null,
            dataForModal: null,
            viewportValue: null,
            viewport: {
                c1: null,
                c2: null,
                c3: null,
                c4: null
            },
            apiPolygons: null,
            geoJsonObj: [],
            areas: null,
            renderer: null,
            polygonViewPane: null,
            markerIcon: null,
            tmpLayer:null,
            search_query:''

        }
    },
    created() {
        this.filtersStore.getFiltersData();
        this.getAreasWithFilters();
        this.filtersStore.$subscribe((mutation, state) => {
            if (mutation.type != 'patch object') {
                this.getAreasWithFilters(false, true);
            }
        })
    },
    mounted() {
        if (window.innerWidth > 1920) {
            this.INIT_ZOOM = 7;
        }
        this.secondLayer = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
            maxZoom: this.MAX_ZOOM,
            minZoom: this.MIN_ZOOM,
        })
        this.firstLayer = L.tileLayer(' https://tiles.openstreetmap.org.ua/tile/{z}/{x}/{y}.png', {
            maxZoom: this.MAX_ZOOM,
            minZoom: this.MIN_ZOOM,
            attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright/">OpenStreetMap</a> contributors, <a href="http://opendatacommons.org/licenses/odbl/">ODbL</a>',
        })

        let baseMaps = {
            "Супутник": this.secondLayer,
            "Схема": this.firstLayer,

        };

        this.map = L.map('map_container', {
            layers: [this.firstLayer, this.secondLayer]
        }).setView(this.INIT_CENTER, this.INIT_ZOOM);
        this.map.setMaxBounds(this.MAX_BOUNDS);
        this.map.addEventListener('moveend', this.moveHandler)
        this.layerControl = L.control.layers(baseMaps).addTo(this.map);
        this.layerControl.setPosition('bottomright')
        this.currentZoom = this.map.getZoom();
        this.map.createPane('polygonPane')
        this.polygonViewPane = this.map.getPane('polygonPane')
        this.polygonViewPane.style.zIndex = 400
        this.renderer = L.canvas({
            pane: this.polygonViewPane,
            padding: 0
        })
    },

    methods: {
        moveHandler() {
            this.viewportValue = this.map.getBounds();
            let y2 = this.viewportValue.getEast();
            let y = this.viewportValue.getWest();
            let x2 = this.viewportValue.getNorth();
            let x = this.viewportValue.getSouth();
            this.viewport.c1 = [x, y];
            this.viewport.c2 = [x2, y];
            this.viewport.c3 = [x2, y2];
            this.viewport.c4 = [x, y2];
            this.currentZoom = this.map.getZoom();
            if (this.currentZoom >= this.SHOW_MARKER_ZOOM) {
                //this.getPolygons();
            } else {
                if (this.polygons.length) {
                    this.polygons.forEach(el => {
                        el.remove();
                    })
                    this.polygons = [];


                }
            }
        },
        polygonsRenderer() {
            let toRenter = []
            let layer = null;
            if (this.polygons.length) {
                this.polygons.forEach(el => {
                    el.remove();
                })
                this.polygons = [];

            }

            this.apiPolygons.forEach(el => {
                layer = L.polygon(el.coordinates, {
                    color: 'red',
                    weight: 0.5,
                    pane: this.polygonViewPane,
                    renderer: this.renderer
                }).addTo(this.map)
                layer.props = {
                    kadastr: el.kadastr,
                    id: el.id
                }
                layer.bindTooltip(`<div>ID: ${el.id}</div><div>kadastr: ${el.kadastr}</div>`)
                layer.on('mouseover', function () {
                    this.setStyle({
                        'fillColor': '#0098ff'
                    });
                    this.openTooltip();
                });
                layer.on('mouseout', function () {
                    this.setStyle({
                        'fillColor': '#ff0000'
                    });
                    this.closeTooltip();
                });
                this.polygons.push(layer)
            })


        },
        polygonClickHandler(item) {
            this.dataForModal = item;
            UIkit.modal(this.$refs.polygon_modal.$el).show();
        },
        getAreasWithFilters(is_paginate, is_filters) {
            let dataToFetch = {
                availability: this.filtersStore.availability,
                region: this.filtersStore.region,
                lease_term: this.filtersStore.lease_term,
                tenant: this.filtersStore.tenant,
                rent: this.filtersStore.rent,
                area: this.filtersStore.area,
                land_price: this.filtersStore.land_price,
                price_per_hectare: this.filtersStore.price_per_hectare,
                perPage: this.filtersStore.perPage
            }
            dataToFetch.search = this.search_query
            dataToFetch.perPage = -1
            axios.get(route('api.areas.filters', dataToFetch)).then(res => {
                this.areas = res.data.data;
                this.areas_count = res.data.paginator.total || 0;
                // this.markers = null;
                 this.addMarkers();
            }).catch(err => {
                console.log(err)
            })
        },
        async getPolygons() {
            try {
                let res = await axios.post(this.lroute('api.polygons'), this.viewport);
                this.apiPolygons = res.data;
                await this.polygonsRenderer();
                this.items = await res.data;
            } catch (err) {
                console.log('%c get data error:', "color:white; background:red;", err.message);
            }
        },
        addMarkers() {
            if (this.markers){
                this.map.removeLayer(this.markers)
            }
            this.markers = L.markerClusterGroup({
                showCoverageOnHover: false,
                chunkedLoading: true,
                animate: true,
                maxClusterRadius: 85,
                spiderLegPolylineOptions: {
                    weight: 1.5, color: '#222', opacity: 1
                },
                iconCreateFunction: function(cluster) {
                        let total = cluster.getAllChildMarkers().length;
                        let in_process = cluster.getAllChildMarkers().filter(el=>{
                            return el.data.is_reserved
                        })
                    let percent_calc =(in_process.length/total)*100 ;

                    let  dasharray = "0 100", // начальные данные псевдомассива отрезка
                        dashoffset ="0", // Длина окружности для фигуры подложки
                        circumference =0; //Длина окружности которую вычислим позже
                    circumference = 2 * Math.PI * 75;
                    const percent = ((percent_calc * 100) / 100);
                    const offset = (percent / 100) * circumference;
                    return L.divIcon({ html: `
                                        <div class="circle__cluster" style="width: 40px; height: 40px">
                                                <svg width="40" height="40" viewBox="0 0 189 189" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="94.5" cy="94.5" r="94.5" fill="#36A363"/>
                                                    <circle
                                                        class="front"
                                                        stroke-dasharray="${offset} ${circumference}"
                                                        stroke-dashoffset="0"
                                                        r="80"
                                                        cx="50%"
                                                        cy="50%"
                                                    />
                                                </svg>
                                                <div class="white__bg">${cluster.getChildCount()}</div>
                                            </div>
                        ` });
                }
            });
            this.markersCount = 0;
            this.areas.forEach(el => {
                this.markerIcon = L.divIcon({
                    className: 'marker-icon',
                    html: `<div class="circle-marker ${el.is_reserved ? 'reserved' : ''}"><div class="in_marker"><span class="rent_proc">${el.rent}%</span></div></div>`,
                    iconSize: [32, 32]
                });
                let marker = L.marker([el.lat, el.lng], {icon: this.markerIcon})
                // Докидываем данные в маркер
                marker.data = el;

                this.markers.addLayer(marker);
                this.markersCount++;
            })
            this.map.addLayer(this.markers);
            this.markers.addEventListener('click', this.markerClickHandler)
        },
        markerClickHandler(e) {
            // Обработка клика на маркер
            this.dataForModal = e.layer.data;
            UIkit.modal(this.$refs.polygon_modal.$el).show()
        },
        changeSkin() {
            if (this.currentZoom >= this.SHOW_MARKER_ZOOM) {
                this.layerControl.addBaseLayer(this.secondLayer, 'secondLayer');
            }
        },

        plusZoom() {
            this.map.zoomIn()
        },
        minusZoom() {
            this.map.zoomOut()
        },
        showInfo(){
            this.$refs.legend.classList.toggle('show');
            this.$refs.info_btn.classList.toggle('show');
        },
        searchHandler(query){
            this.search_query = query;
            this.getAreasWithFilters();
        }
    }
}
</script>

<template>
    <div class="map__wrapper">
        <div id="map_container" ref="map" class="map__container">

        </div>
        <div class="map__control">
            <a href="" ref="info_btn" class="info__btn btn" @click.prevent="showInfo">
                <icon name="info" class-name="info-icon"/>
                <icon name="close" style="display: none;" class-name="close-icon"/>
            </a>
            <a href="" @click.prevent="plusZoom" class="plus__btn btn control__btn">
                <icon name="plus"/>
            </a>
            <a href="" @click.prevent="minusZoom" class="minus__btn btn control__btn">
                <icon name="minus"/>
            </a>
        </div>
        <PolygonModal :area_info="dataForModal" ref="polygon_modal"/>
        <OfferPriceModal/>
        <div class="map_legend" ref="legend">
            <div class="legend__item">
                <span class="color" style="background-color: #36A363">

                </span>
                <span class="title">
                    Земельна ділянка яку можна придбати
                </span>
            </div>
            <div class="legend__item">
                <span class="color" style="background-color: #FFCE1F">

                </span>
                <span class="title">
                    Земельна ділянка в процесі укладення договору купівлі-продажу
                </span>
            </div>
        </div>
    </div>
    <MapFilter @search="searchHandler" :total_areas ="areas_count"/>
</template>

<style lang="scss">
.leaflet-div-icon{
    border: none;
    background-color: transparent;
}
.circle__cluster{
    position: relative;
    display: flex;

    .front {
        stroke: $yellow;
        fill: transparent;
        stroke-width: 30px;
        transform-origin: center;
        transform: rotate(-90deg);
        transition: stroke-dasharray 1s ease;
        z-index: 220;
        position: relative;
    }
    .white__bg{
        width: 28px;
        height: 28px;
        background-color: white;
        font-weight: 500;
        color: $black;
        z-index: 210;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 6px;
        left: 6px;

    }
}
.map__wrapper {
    position: relative;

    .info__btn{
        &.show{
            .info-icon{
                display: none;
            }
            .close-icon{
                display: block !important;
            }
        }
    }

    .map_legend {
        &.show{
            bottom: 100px;
        }
        position: absolute;
        bottom: -100%;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        transition: 0.3s;
        gap: 15px;
        padding: 10px 15px;
        background-color: white;
        border-radius: 20px;
        z-index: 5;
        .legend__item{
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            font-size: 14px;
            line-height: 110%;
            .color{
                width: 15px;
                height: 15px;
                min-width: 15px;
                min-height: 15px;
                border-radius: 100%;
            }
            .title{
                color:$black
            }
        }
    }
}

#map_container {

    position: relative;
    width: 100%;
    height: calc(100vh - 105px);
    z-index: 0;

    .leaflet-pane, .leaflet-tile, .leaflet-marker-icon, .leaflet-marker-shadow, .leaflet-tile-container, .leaflet-pane > svg, .leaflet-pane > canvas, .leaflet-zoom-box, .leaflet-image-layer, .leaflet-layer {
        right: 0;
    }

    .leaflet-control-zoom {
        display: none;
    }

    //.leaflet-control-container {
    //    display: none;
    //}

    .leaflet__map {
        position: relative;
        z-index: 1;
    }

    .reserved.circle-marker {
        background: $yellow;
    }

    .circle-marker {
        width: 18px;
        height: 18px;
        background: $green;
        border-radius: 50%;
        border: 3px solid white;

        .in_marker {
            display: flex;
            justify-content: center;

            .rent_proc {
                text-align: center;
                position: absolute;
                top: -17px;
                color: $black;
                font-size: 13px;
                margin: 0 auto;
                font-weight: 600;
                left: 50%;
                transform: translateX(-60%);
            }
        }
    }

    .marker-cluster {
        background-color: #FFCE1F;

        &:before {
            content: '';
            width: 0px;
            height: 0px;
            border-style: solid;
            border-width: 10px 6px 0 6px;
            border-color: #FFCE1F transparent transparent transparent;
            position: absolute;
            bottom: 0px;
            left: 50%;
            transform: translate(-50%, 90%)
        }

    }

    .marker-cluster div {
        background-color: white;
        color: $black;
        font-size: 14px;
        font-weight: 500;
        line-height: 1;
    }


}

.map__control {
    position: absolute;
    bottom: 70px;
    right: 100px;
    z-index: 5;
    display: flex;
    flex-direction: column;
    width: fit-content;

    .btn {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .info__btn {
        background-color: $black;
        color: white !important;
        margin-bottom: 20px;
    }

    .control__btn {
        background-color: white;
        color: #333333 !important;
        margin-bottom: 5px;
    }
}


@media screen and (max-width: $breakpoint-df) {
    #map_container {
        height: calc(100vh - 70px);


    }
    .map__control {
        left: 20px;
        right: initial;
        bottom: 30px;


    }
}

</style>
