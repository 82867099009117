<script>
import Icon from "@/components/icon.vue";
import MapListFilter from "@/components/mapList/map-list-filter.vue";
import UIkit from "uikit";
import Simplebar from 'simplebar-vue';
import 'simplebar-vue/dist/simplebar.min.css';
import PaginationComponent from "@/components/pagination-component.vue";
import OfferPriceModal from "@/components/offer-price-modal.vue";
import axios from "axios";
import vueDebounce from 'vue-debounce'
import {useFiltersStore} from "@/store/filter.js";
import {log} from "uikit/src/js/api/log.js";
import FiltersResult from "@/components/partial/filters-result.vue";

export default {
    name: "list-component",
    props: {
        is_cabinet: {
            default: false
        }
    },
    components: {FiltersResult, OfferPriceModal, PaginationComponent, MapListFilter, Icon, Simplebar},
    directives: {
        debounce: vueDebounce({lock: true})
    },
    setup() {
        const filtersStore = useFiltersStore()
        return {filtersStore}
    },
    created() {
        this.filtersStore.getFiltersData();
        this.getAreasWithFilters();
        this.filtersStore.$subscribe((mutation, state) => {
            if (mutation.type != 'patch object') {
                this.getAreasWithFilters(false, true);
            }
        })
    },
    data() {
        return {
            to_view: 25,
            areas: [],
            pagination: {},
            page: 1,
            sorting: [],
            sort_types: {
                0: null,
                1: 'asc',
                2: 'desc',
            },
            search_query: '',
        }
    },
    methods: {
        resetFilters() {
            this.filtersStore.resetFilters()
        },
        paginate(data) {
            this.page = data;
            this.getAreasWithFilters(true);

        },
        getAreasWithFilters(is_paginate, is_filters) {
            let dataToFetch = {
                availability: this.filtersStore.availability,
                region: this.filtersStore.region,
                lease_term: this.filtersStore.lease_term,
                tenant: this.filtersStore.tenant,
                rent: this.filtersStore.rent,
                area: this.filtersStore.area,
                land_price: this.filtersStore.land_price,
                price_per_hectare: this.filtersStore.price_per_hectare,
                perPage: this.filtersStore.perPage
            }
            if (this.sorting.length) {
                dataToFetch.sort = this.sorting
            }
            dataToFetch.search = this.search_query
            dataToFetch.page = is_filters ? 1 : this.page

            axios.get(route('api.areas.filters', dataToFetch)).then(res => {
                this.areas = res.data.data;
                this.pagination = res.data.paginator;
                if (is_paginate) {
                    window.scrollTo(0, 0);
                }
            }).catch(err => {
                console.log(err)
            })
        },
        searchParams() {
            let searchParams = new URLSearchParams(window.location.search);
            return {
                get: (param) => {
                    if (searchParams.has(param)) return searchParams.get(param);
                },
                delete: (param) => {
                    if (searchParams.has(param)) searchParams.delete(param);
                },
                set: (param, value) => {
                    searchParams.set(param, value);
                }
            }
        },
        searchHandler(query, event) {
            this.search_query = query
            this.getAreasWithFilters();
        },
        getAreas() {
            axios.get(this.lroute('api.areas.list', {perPage: this.filtersStore.perPage})).then(res => {
                this.areas = res.data.data;
                this.pagination = res.data.paginator;
            }).catch(err => {
                console.log('%c get areas error:', "color:white; background:red;", err.message)
            })
        },
        setView(cnt) {
            this.filtersStore.perPage = cnt;
            this.page = 1;
            this.getAreasWithFilters();
            UIkit.dropdown(this.$refs.list_drop).hide(0);
        },
        showMobileInfo() {
            if (window.innerWidth <= 768) {
                event.preventDefault();
                let listRow = event.target.closest('.list__row');
                listRow.querySelector('.mobile__info-section').classList.toggle('open')
                listRow.querySelector('.mob__action-btn').classList.toggle('open')
            }

        },
        sendRequest(id) {
            axios.post(this.lroute('area.request'), {id: id}).then(res => {
                this.$mitt.emit('request-success')

            })
        },
        offerPrise(id) {
            this.$mitt.emit('offer-price', id)
        },
        toggleSortDirection(currentDir) {

            if (currentDir === 'asc') return 'desc'
            if (currentDir === 'desc') return 'asc'
            // if (!currentDir) return 'asc'

        },
        clearSortVisualization() {
            for (let el in this.$refs) {
                this.$refs[el].classList.remove('asc')
                this.$refs[el].classList.remove('desc')
            }
            this.sorting.forEach(el => {
                this.$refs[el.field].classList.add(el.dir)
            })
        },

        sortHandler(slug, is_reset = false) {
            //?sort[0][field]=id&sort[0][dir]=asc&sort[1][field]=kadastr&sort[1][dir]=desc
            let currentClick = {
                field: slug,
                dir: 'asc'
            }

                if (event.shiftKey || is_reset) {
                    if (!this.sorting.length && !event.shiftKey) {
                        this.sorting = [currentClick]
                    } else {
                        let findEl = this.sorting.find(el => {
                            return el.field === slug;
                        })
                        if (event.shiftKey) {
                            if (findEl) {
                                findEl.dir = this.toggleSortDirection(findEl.dir);
                            } else {
                                this.sorting.push(currentClick)
                            }
                        } else {
                            if (findEl) {
                                findEl.dir = this.toggleSortDirection(findEl.dir);
                            } else {
                                this.sorting = [currentClick]
                            }
                        }

                    }
                } else {
                    if (!this.sorting.length) {
                        this.sorting = [currentClick]
                    } else {
                        let findEl = this.sorting.find(el => {
                            return el.field === slug;
                        })
                        if (findEl) {
                            findEl.dir = this.toggleSortDirection(findEl.dir);
                            this.sorting = [findEl]
                        } else {
                            this.sorting = [currentClick]
                        }

                    }
                }
            console.log(is_reset, 'is_reset')
            if (is_reset) {
                console.log('reset sort')
                this.sorting = this.sorting.filter(el => {
                    console.log(el.field, slug)
                   return  el.field !== slug
                })
                console.log(this.sorting)
            }
            this.clearSortVisualization();

            this.getAreasWithFilters()

        }
    }
}
</script>

<template>
    <div class="map__list-top">
        <div class="container list__head">
            <div class="filter__drop">
                <a href="" class="filter__btn">
                    <icon name="filter"/>
                    Фільтри
                </a>
                <div uk-dropdown="mode: click" class="filter__dropdown">
                    <MapListFilter></MapListFilter>
                </div>
            </div>
            <a href="#mobile_list_filters" uk-toggle class="filter__btn-mobile" style="display: none;">
                <icon name="filter"/>
                Фільтри
            </a>
            <div class="view__item">
                <button class="show__items">
                    <Icon name="eye" class="icon"></Icon>
                    Показати
                </button>
                <div class="items__drop">
                    <a href="" class="items__count" v-text="filtersStore.perPage"></a>
                    <div uk-dropdown="mode: click" class="view__list-drop" ref="list_drop">
                        <ul>
                            <li @click="setView(10)">10</li>
                            <li @click="setView(25)">25</li>
                            <li @click="setView(50)">50</li>
                            <li @click="setView(100)">100</li>
                        </ul>
                    </div>
                </div>

            </div>
            <div class="search__item">
                <div class="search__wrapper">
                    <span class="icon">
                        <Icon name="list-search"/>
                    </span>
                    <input type="text" v-model="search_query" v-debounce:400ms="searchHandler"
                           placeholder="Введіть кадастровий номер або ID" class="search__input">
                </div>
            </div>
            <a class="search__mob" href="#mobile_list_filters" uk-toggle style="display: none;">
                <Icon name="search"/>
            </a>
            <slot></slot>
            <div class="map__list-toggle" v-if="!is_cabinet">
                <a :href="this.lroute('map')" class="map__btn ">
        <span class="icon">
          <Icon name="map"/>
        </span>
                    <span class="title">
            Карта
        </span>
                </a>
                <a :href="this.lroute('map_list')" class="map__btn active">
            <span class="icon">
             <Icon name="list"/>
        </span>
                    <span class="title">
            Список
        </span>
                </a>

            </div>
        </div>
        <div class="container">
            <FiltersResult/>
            <div class="list__info">
                *Для сортування по кількох полях натисність клавішу Shift, а потім натисність на назву стовпця
            </div>
        </div>

        <div class="list__wrapper">
            <Simplebar data-simplebar-auto-hide="false">
                <div class="list">
                    <div class="list__row list__header">
                        <div class="list__col hide__mobile">
                            <div class="title">ID</div>
                        </div>
                        <div class="list__col hide__mobile" ref="region_id" @click="sortHandler('region_id')">
                            <div class="title">Область</div>
                            <div class="arrows">
                                <div class="arr__top">
                                    <Icon name="triangle-top"/>
                                </div>
                                <div class="arr__bot">
                                    <Icon name="triangle-bot"/>
                                </div>
                            </div>
                            <span @click.stop="sortHandler('region_id', true)" class="reset__sort"
                                  style="display: none" uk-tooltip="Скинути сортування">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"
                                       fill="none">
                                               <path
                                                   d="M6.52984 6.00023L9.64234 2.89242C9.70214 2.81956 9.7327 2.72706 9.72808 2.63291C9.72345 2.53877 9.68398 2.44971 9.61733 2.38306C9.55068 2.31641 9.46162 2.27693 9.36747 2.27231C9.27333 2.26768 9.18083 2.29824 9.10797 2.35804L6.00016 5.47054L2.89234 2.35804C2.81948 2.29824 2.72698 2.26768 2.63284 2.27231C2.53869 2.27693 2.44964 2.31641 2.38299 2.38306C2.31633 2.44971 2.27686 2.53877 2.27223 2.63291C2.26761 2.72706 2.29817 2.81956 2.35797 2.89242L5.47047 6.00023L2.35797 9.10804C2.28755 9.17914 2.24805 9.27516 2.24805 9.37523C2.24805 9.4753 2.28755 9.57132 2.35797 9.64242C2.42965 9.71173 2.52545 9.75047 2.62516 9.75047C2.72486 9.75047 2.82067 9.71173 2.89234 9.64242L6.00016 6.52992L9.10797 9.64242C9.17965 9.71173 9.27545 9.75047 9.37516 9.75047C9.47486 9.75047 9.57067 9.71173 9.64234 9.64242C9.71276 9.57132 9.75226 9.4753 9.75226 9.37523C9.75226 9.27516 9.71276 9.17914 9.64234 9.10804L6.52984 6.00023Z"
                                                   fill="white"/>
                                         </svg>
                            </span>
                        </div>
                        <div class="list__col" ref="district" @click="sortHandler('district')">

                            <div class="title">
                                <div class="title show__mobile" style="display: none;">Область</div>
                                Район
                            </div>
                            <div class="arrows">
                                <div class="arr__top">
                                    <Icon name="triangle-top"/>
                                </div>
                                <div class="arr__bot">
                                    <Icon name="triangle-bot"/>
                                </div>
                            </div>
                            <span @click.stop="sortHandler('district', true)" class="reset__sort"
                                  style="display: none" uk-tooltip="Скинути сортування">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"
                                       fill="none">
                                               <path
                                                   d="M6.52984 6.00023L9.64234 2.89242C9.70214 2.81956 9.7327 2.72706 9.72808 2.63291C9.72345 2.53877 9.68398 2.44971 9.61733 2.38306C9.55068 2.31641 9.46162 2.27693 9.36747 2.27231C9.27333 2.26768 9.18083 2.29824 9.10797 2.35804L6.00016 5.47054L2.89234 2.35804C2.81948 2.29824 2.72698 2.26768 2.63284 2.27231C2.53869 2.27693 2.44964 2.31641 2.38299 2.38306C2.31633 2.44971 2.27686 2.53877 2.27223 2.63291C2.26761 2.72706 2.29817 2.81956 2.35797 2.89242L5.47047 6.00023L2.35797 9.10804C2.28755 9.17914 2.24805 9.27516 2.24805 9.37523C2.24805 9.4753 2.28755 9.57132 2.35797 9.64242C2.42965 9.71173 2.52545 9.75047 2.62516 9.75047C2.72486 9.75047 2.82067 9.71173 2.89234 9.64242L6.00016 6.52992L9.10797 9.64242C9.17965 9.71173 9.27545 9.75047 9.37516 9.75047C9.47486 9.75047 9.57067 9.71173 9.64234 9.64242C9.71276 9.57132 9.75226 9.4753 9.75226 9.37523C9.75226 9.27516 9.71276 9.17914 9.64234 9.10804L6.52984 6.00023Z"
                                                   fill="white"/>
                                         </svg>
                            </span>
                        </div>

                        <div class="list__col hide__mobile" ref="kadastr" @click="sortHandler('kadastr')">
                            <div class="title">Кадастровий номер</div>
                            <div class="arrows">
                                <div class="arr__top">
                                    <Icon name="triangle-top"/>
                                </div>
                                <div class="arr__bot">
                                    <Icon name="triangle-bot"/>
                                </div>
                            </div>
                            <span @click.stop="sortHandler('kadastr', true)" class="reset__sort"
                                  style="display: none" uk-tooltip="Скинути сортування">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"
                                       fill="none">
                                               <path
                                                   d="M6.52984 6.00023L9.64234 2.89242C9.70214 2.81956 9.7327 2.72706 9.72808 2.63291C9.72345 2.53877 9.68398 2.44971 9.61733 2.38306C9.55068 2.31641 9.46162 2.27693 9.36747 2.27231C9.27333 2.26768 9.18083 2.29824 9.10797 2.35804L6.00016 5.47054L2.89234 2.35804C2.81948 2.29824 2.72698 2.26768 2.63284 2.27231C2.53869 2.27693 2.44964 2.31641 2.38299 2.38306C2.31633 2.44971 2.27686 2.53877 2.27223 2.63291C2.26761 2.72706 2.29817 2.81956 2.35797 2.89242L5.47047 6.00023L2.35797 9.10804C2.28755 9.17914 2.24805 9.27516 2.24805 9.37523C2.24805 9.4753 2.28755 9.57132 2.35797 9.64242C2.42965 9.71173 2.52545 9.75047 2.62516 9.75047C2.72486 9.75047 2.82067 9.71173 2.89234 9.64242L6.00016 6.52992L9.10797 9.64242C9.17965 9.71173 9.27545 9.75047 9.37516 9.75047C9.47486 9.75047 9.57067 9.71173 9.64234 9.64242C9.71276 9.57132 9.75226 9.4753 9.75226 9.37523C9.75226 9.27516 9.71276 9.17914 9.64234 9.10804L6.52984 6.00023Z"
                                                   fill="white"/>
                                         </svg>
                            </span>
                        </div>
                        <div class="list__col hide__mobile" ref="area" @click="sortHandler('area')">
                            <div class="title">Площа</div>
                            <div class="arrows">
                                <div class="arr__top">
                                    <Icon name="triangle-top"/>
                                </div>
                                <div class="arr__bot">
                                    <Icon name="triangle-bot"/>
                                </div>
                            </div>
                            <span @click.stop="sortHandler('area', true)" class="reset__sort"
                                  style="display: none" uk-tooltip="Скинути сортування">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"
                                       fill="none">
                                               <path
                                                   d="M6.52984 6.00023L9.64234 2.89242C9.70214 2.81956 9.7327 2.72706 9.72808 2.63291C9.72345 2.53877 9.68398 2.44971 9.61733 2.38306C9.55068 2.31641 9.46162 2.27693 9.36747 2.27231C9.27333 2.26768 9.18083 2.29824 9.10797 2.35804L6.00016 5.47054L2.89234 2.35804C2.81948 2.29824 2.72698 2.26768 2.63284 2.27231C2.53869 2.27693 2.44964 2.31641 2.38299 2.38306C2.31633 2.44971 2.27686 2.53877 2.27223 2.63291C2.26761 2.72706 2.29817 2.81956 2.35797 2.89242L5.47047 6.00023L2.35797 9.10804C2.28755 9.17914 2.24805 9.27516 2.24805 9.37523C2.24805 9.4753 2.28755 9.57132 2.35797 9.64242C2.42965 9.71173 2.52545 9.75047 2.62516 9.75047C2.72486 9.75047 2.82067 9.71173 2.89234 9.64242L6.00016 6.52992L9.10797 9.64242C9.17965 9.71173 9.27545 9.75047 9.37516 9.75047C9.47486 9.75047 9.57067 9.71173 9.64234 9.64242C9.71276 9.57132 9.75226 9.4753 9.75226 9.37523C9.75226 9.27516 9.71276 9.17914 9.64234 9.10804L6.52984 6.00023Z"
                                                   fill="white"/>
                                         </svg>
                            </span>
                        </div>
                        <div class="list__col" ref="land_price" @click="sortHandler('land_price')">
                            <div class="title">
                                <span class="desk">Ціна ділянки</span>
                                <span class="mob" style="display: none">
                                Ціна діл.
                                </span>
                            </div>
                            <div class="arrows">
                                <div class="arr__top">
                                    <Icon name="triangle-top"/>
                                </div>
                                <div class="arr__bot">
                                    <Icon name="triangle-bot"/>
                                </div>
                            </div>
                            <span @click.stop="sortHandler('land_price', true)" class="reset__sort"
                                  style="display: none" uk-tooltip="Скинути сортування">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"
                                       fill="none">
                                               <path
                                                   d="M6.52984 6.00023L9.64234 2.89242C9.70214 2.81956 9.7327 2.72706 9.72808 2.63291C9.72345 2.53877 9.68398 2.44971 9.61733 2.38306C9.55068 2.31641 9.46162 2.27693 9.36747 2.27231C9.27333 2.26768 9.18083 2.29824 9.10797 2.35804L6.00016 5.47054L2.89234 2.35804C2.81948 2.29824 2.72698 2.26768 2.63284 2.27231C2.53869 2.27693 2.44964 2.31641 2.38299 2.38306C2.31633 2.44971 2.27686 2.53877 2.27223 2.63291C2.26761 2.72706 2.29817 2.81956 2.35797 2.89242L5.47047 6.00023L2.35797 9.10804C2.28755 9.17914 2.24805 9.27516 2.24805 9.37523C2.24805 9.4753 2.28755 9.57132 2.35797 9.64242C2.42965 9.71173 2.52545 9.75047 2.62516 9.75047C2.72486 9.75047 2.82067 9.71173 2.89234 9.64242L6.00016 6.52992L9.10797 9.64242C9.17965 9.71173 9.27545 9.75047 9.37516 9.75047C9.47486 9.75047 9.57067 9.71173 9.64234 9.64242C9.71276 9.57132 9.75226 9.4753 9.75226 9.37523C9.75226 9.27516 9.71276 9.17914 9.64234 9.10804L6.52984 6.00023Z"
                                                   fill="white"/>
                                         </svg>
                            </span>
                        </div>
                        <div class="list__col hide__mobile" ref="price_per_hectare"
                             @click="sortHandler('price_per_hectare')">
                            <div class="title">Ціна за га</div>
                            <div class="arrows">
                                <div class="arr__top">
                                    <Icon name="triangle-top"/>
                                </div>
                                <div class="arr__bot">
                                    <Icon name="triangle-bot"/>
                                </div>
                            </div>
                            <span @click.stop="sortHandler('price_per_hectare', true)" class="reset__sort"
                                  style="display: none" uk-tooltip="Скинути сортування">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"
                                       fill="none">
                                               <path
                                                   d="M6.52984 6.00023L9.64234 2.89242C9.70214 2.81956 9.7327 2.72706 9.72808 2.63291C9.72345 2.53877 9.68398 2.44971 9.61733 2.38306C9.55068 2.31641 9.46162 2.27693 9.36747 2.27231C9.27333 2.26768 9.18083 2.29824 9.10797 2.35804L6.00016 5.47054L2.89234 2.35804C2.81948 2.29824 2.72698 2.26768 2.63284 2.27231C2.53869 2.27693 2.44964 2.31641 2.38299 2.38306C2.31633 2.44971 2.27686 2.53877 2.27223 2.63291C2.26761 2.72706 2.29817 2.81956 2.35797 2.89242L5.47047 6.00023L2.35797 9.10804C2.28755 9.17914 2.24805 9.27516 2.24805 9.37523C2.24805 9.4753 2.28755 9.57132 2.35797 9.64242C2.42965 9.71173 2.52545 9.75047 2.62516 9.75047C2.72486 9.75047 2.82067 9.71173 2.89234 9.64242L6.00016 6.52992L9.10797 9.64242C9.17965 9.71173 9.27545 9.75047 9.37516 9.75047C9.47486 9.75047 9.57067 9.71173 9.64234 9.64242C9.71276 9.57132 9.75226 9.4753 9.75226 9.37523C9.75226 9.27516 9.71276 9.17914 9.64234 9.10804L6.52984 6.00023Z"
                                                   fill="white"/>
                                         </svg>
                            </span>
                        </div>
                        <div class="list__col hide__mobile" ref="net_rental_income"
                             @click="sortHandler('net_rental_income')">
                            <div class="title">Сума оренди</div>
                            <div class="arrows">
                                <div class="arr__top">
                                    <Icon name="triangle-top"/>
                                </div>
                                <div class="arr__bot">
                                    <Icon name="triangle-bot"/>
                                </div>
                            </div>
                            <span @click.stop="sortHandler('net_rental_income', true)" class="reset__sort"
                                  style="display: none" uk-tooltip="Скинути сортування">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"
                                       fill="none">
                                               <path
                                                   d="M6.52984 6.00023L9.64234 2.89242C9.70214 2.81956 9.7327 2.72706 9.72808 2.63291C9.72345 2.53877 9.68398 2.44971 9.61733 2.38306C9.55068 2.31641 9.46162 2.27693 9.36747 2.27231C9.27333 2.26768 9.18083 2.29824 9.10797 2.35804L6.00016 5.47054L2.89234 2.35804C2.81948 2.29824 2.72698 2.26768 2.63284 2.27231C2.53869 2.27693 2.44964 2.31641 2.38299 2.38306C2.31633 2.44971 2.27686 2.53877 2.27223 2.63291C2.26761 2.72706 2.29817 2.81956 2.35797 2.89242L5.47047 6.00023L2.35797 9.10804C2.28755 9.17914 2.24805 9.27516 2.24805 9.37523C2.24805 9.4753 2.28755 9.57132 2.35797 9.64242C2.42965 9.71173 2.52545 9.75047 2.62516 9.75047C2.72486 9.75047 2.82067 9.71173 2.89234 9.64242L6.00016 6.52992L9.10797 9.64242C9.17965 9.71173 9.27545 9.75047 9.37516 9.75047C9.47486 9.75047 9.57067 9.71173 9.64234 9.64242C9.71276 9.57132 9.75226 9.4753 9.75226 9.37523C9.75226 9.27516 9.71276 9.17914 9.64234 9.10804L6.52984 6.00023Z"
                                                   fill="white"/>
                                         </svg>
                            </span>
                        </div>
                        <div class="list__col hide__mobile" ref="lease_term" @click="sortHandler('lease_term')">
                            <div class="title">Термін</div>
                            <div class="arrows">
                                <div class="arr__top">
                                    <Icon name="triangle-top"/>
                                </div>
                                <div class="arr__bot">
                                    <Icon name="triangle-bot"/>
                                </div>
                            </div>
                            <span @click.stop="sortHandler('lease_term', true)" class="reset__sort"
                                  style="display: none" uk-tooltip="Скинути сортування">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"
                                       fill="none">
                                               <path
                                                   d="M6.52984 6.00023L9.64234 2.89242C9.70214 2.81956 9.7327 2.72706 9.72808 2.63291C9.72345 2.53877 9.68398 2.44971 9.61733 2.38306C9.55068 2.31641 9.46162 2.27693 9.36747 2.27231C9.27333 2.26768 9.18083 2.29824 9.10797 2.35804L6.00016 5.47054L2.89234 2.35804C2.81948 2.29824 2.72698 2.26768 2.63284 2.27231C2.53869 2.27693 2.44964 2.31641 2.38299 2.38306C2.31633 2.44971 2.27686 2.53877 2.27223 2.63291C2.26761 2.72706 2.29817 2.81956 2.35797 2.89242L5.47047 6.00023L2.35797 9.10804C2.28755 9.17914 2.24805 9.27516 2.24805 9.37523C2.24805 9.4753 2.28755 9.57132 2.35797 9.64242C2.42965 9.71173 2.52545 9.75047 2.62516 9.75047C2.72486 9.75047 2.82067 9.71173 2.89234 9.64242L6.00016 6.52992L9.10797 9.64242C9.17965 9.71173 9.27545 9.75047 9.37516 9.75047C9.47486 9.75047 9.57067 9.71173 9.64234 9.64242C9.71276 9.57132 9.75226 9.4753 9.75226 9.37523C9.75226 9.27516 9.71276 9.17914 9.64234 9.10804L6.52984 6.00023Z"
                                                   fill="white"/>
                                         </svg>
                            </span>
                        </div>
                        <div class="list__col" ref="rent" @click="sortHandler('rent')">
                            <div class="title">
                                <span class="desk">Прибуток</span>
                                <span class="mob" style="display: none;">
                                Прибут.
                                </span>
                            </div>
                            <div class="arrows">
                                <div class="arr__top">
                                    <Icon name="triangle-top"/>
                                </div>
                                <div class="arr__bot">
                                    <Icon name="triangle-bot"/>
                                </div>
                            </div>
                            <span @click.stop="sortHandler('rent', true)" class="reset__sort"
                                  style="display: none" uk-tooltip="Скинути сортування">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"
                                       fill="none">
                                               <path
                                                   d="M6.52984 6.00023L9.64234 2.89242C9.70214 2.81956 9.7327 2.72706 9.72808 2.63291C9.72345 2.53877 9.68398 2.44971 9.61733 2.38306C9.55068 2.31641 9.46162 2.27693 9.36747 2.27231C9.27333 2.26768 9.18083 2.29824 9.10797 2.35804L6.00016 5.47054L2.89234 2.35804C2.81948 2.29824 2.72698 2.26768 2.63284 2.27231C2.53869 2.27693 2.44964 2.31641 2.38299 2.38306C2.31633 2.44971 2.27686 2.53877 2.27223 2.63291C2.26761 2.72706 2.29817 2.81956 2.35797 2.89242L5.47047 6.00023L2.35797 9.10804C2.28755 9.17914 2.24805 9.27516 2.24805 9.37523C2.24805 9.4753 2.28755 9.57132 2.35797 9.64242C2.42965 9.71173 2.52545 9.75047 2.62516 9.75047C2.72486 9.75047 2.82067 9.71173 2.89234 9.64242L6.00016 6.52992L9.10797 9.64242C9.17965 9.71173 9.27545 9.75047 9.37516 9.75047C9.47486 9.75047 9.57067 9.71173 9.64234 9.64242C9.71276 9.57132 9.75226 9.4753 9.75226 9.37523C9.75226 9.27516 9.71276 9.17914 9.64234 9.10804L6.52984 6.00023Z"
                                                   fill="white"/>
                                         </svg>
                            </span>
                        </div>
                        <div class="list__col hide__mobile" ref="is_reserved" @click="sortHandler('is_reserved')">
                            <div class="title">Статус</div>
                            <div class="arrows">
                                <div class="arr__top">
                                    <Icon name="triangle-top"/>
                                </div>
                                <div class="arr__bot">
                                    <Icon name="triangle-bot"/>
                                </div>
                            </div>
                            <span @click.stop="sortHandler('is_reserved', true)" class="reset__sort"
                                  style="display: none" uk-tooltip="Скинути сортування">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"
                                       fill="none">
                                               <path
                                                   d="M6.52984 6.00023L9.64234 2.89242C9.70214 2.81956 9.7327 2.72706 9.72808 2.63291C9.72345 2.53877 9.68398 2.44971 9.61733 2.38306C9.55068 2.31641 9.46162 2.27693 9.36747 2.27231C9.27333 2.26768 9.18083 2.29824 9.10797 2.35804L6.00016 5.47054L2.89234 2.35804C2.81948 2.29824 2.72698 2.26768 2.63284 2.27231C2.53869 2.27693 2.44964 2.31641 2.38299 2.38306C2.31633 2.44971 2.27686 2.53877 2.27223 2.63291C2.26761 2.72706 2.29817 2.81956 2.35797 2.89242L5.47047 6.00023L2.35797 9.10804C2.28755 9.17914 2.24805 9.27516 2.24805 9.37523C2.24805 9.4753 2.28755 9.57132 2.35797 9.64242C2.42965 9.71173 2.52545 9.75047 2.62516 9.75047C2.72486 9.75047 2.82067 9.71173 2.89234 9.64242L6.00016 6.52992L9.10797 9.64242C9.17965 9.71173 9.27545 9.75047 9.37516 9.75047C9.47486 9.75047 9.57067 9.71173 9.64234 9.64242C9.71276 9.57132 9.75226 9.4753 9.75226 9.37523C9.75226 9.27516 9.71276 9.17914 9.64234 9.10804L6.52984 6.00023Z"
                                                   fill="white"/>
                                         </svg>
                            </span>
                        </div>
                        <div class="list__col hide__mobile" ref="tenant" @click="sortHandler('tenant')">
                            <div class="title">Орендар</div>
                            <div class="arrows">
                                <div class="arr__top">
                                    <Icon name="triangle-top"/>
                                </div>
                                <div class="arr__bot">
                                    <Icon name="triangle-bot"/>
                                </div>
                            </div>
                            <span @click.stop="sortHandler('tenant', true)" class="reset__sort"
                                  style="display: none" uk-tooltip="Скинути сортування">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"
                                       fill="none">
                                               <path
                                                   d="M6.52984 6.00023L9.64234 2.89242C9.70214 2.81956 9.7327 2.72706 9.72808 2.63291C9.72345 2.53877 9.68398 2.44971 9.61733 2.38306C9.55068 2.31641 9.46162 2.27693 9.36747 2.27231C9.27333 2.26768 9.18083 2.29824 9.10797 2.35804L6.00016 5.47054L2.89234 2.35804C2.81948 2.29824 2.72698 2.26768 2.63284 2.27231C2.53869 2.27693 2.44964 2.31641 2.38299 2.38306C2.31633 2.44971 2.27686 2.53877 2.27223 2.63291C2.26761 2.72706 2.29817 2.81956 2.35797 2.89242L5.47047 6.00023L2.35797 9.10804C2.28755 9.17914 2.24805 9.27516 2.24805 9.37523C2.24805 9.4753 2.28755 9.57132 2.35797 9.64242C2.42965 9.71173 2.52545 9.75047 2.62516 9.75047C2.72486 9.75047 2.82067 9.71173 2.89234 9.64242L6.00016 6.52992L9.10797 9.64242C9.17965 9.71173 9.27545 9.75047 9.37516 9.75047C9.47486 9.75047 9.57067 9.71173 9.64234 9.64242C9.71276 9.57132 9.75226 9.4753 9.75226 9.37523C9.75226 9.27516 9.71276 9.17914 9.64234 9.10804L6.52984 6.00023Z"
                                                   fill="white"/>
                                         </svg>
                            </span>
                        </div>
                        <div class="list__col hide__mobile without__row">
                            <div class="title">Дія</div>

                        </div>
                        <div class="list__col without__row mobile__action" style="display: none;">

                        </div>

                    </div>
                    <div v-for="area in areas" class="list__row" @click="showMobileInfo">
                        <div class="list__col hide__mobile">

                            <a :href="this.lroute('area.show', area.object_id)">{{ area.id }}</a>
                        </div>
                        <div class="list__col hide__mobile">
                            {{ area.region.title }}
                        </div>
                        <div class="list__col">
                            <div class="show__mobile" style="display: none">
                                {{ area.region.title }}
                            </div>
                            <div>
                                {{ area.district }}
                            </div>
                        </div>

                        <div class="list__col hide__mobile">
                            {{ area.kadastr }}
                        </div>
                        <div class="list__col hide__mobile">
                            {{ area.area }} га
                        </div>
                        <div class="list__col">
                            <b>{{ area.land_price }} грн.</b>
                        </div>
                        <div class="list__col hide__mobile">
                            <b> {{ area.price_per_hectare }} грн.</b>
                        </div>
                        <div class="list__col hide__mobile">
                            {{ area.net_rental_income }} грн.
                        </div>
                        <div class="list__col hide__mobile">
                            {{ area.lease_term }}
                        </div>
                        <div class="list__col">
                            <b> {{ area.rent }} %</b>
                        </div>
                        <div class="list__col hide__mobile">
                            {{ area.is_reserved ? 'Зарезервовано' : 'Не зарезервовано' }}
                        </div>
                        <div class="list__col hide__mobile">
                            {{ area.tenant }}
                        </div>
                        <div class="list__col without__row hide__mobile">
                            <template v-if="$global.auth">
                                <a href="" class="action__btn">
                                    <Icon name="action"/>
                                </a>
                                <div class="action__drop"
                                     uk-dropdown="mode: click; pos: left-center; animation: slide-right; animate-out: true">
                                    <div class="action__drop-wrapper">
                                        <a href="#" @click.prevent="sendRequest(area.id)"
                                           class="action__btn-item white">Подати заявку</a>
                                        <a href="#" v-if="area.show_custom_price" @click.prevent="offerPrise(area.id)"
                                           class="action__btn-item">Запропонувати ціну</a>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <a href="#login_modal" uk-toggle class="action__btn">
                                    <Icon name="user"/>
                                </a>
                            </template>
                        </div>
                        <div class="list__col mobile__action without__row" style="display: none">
                            <a href="" @click.prevent="" class="mob__action-btn">
                                <Icon name="arrow-down"/>
                            </a>
                        </div>
                        <div class="mobile__info-section" style="display: none">
                            <div class="mobile__rows">
                                <div class="mobile__info-row">
                                    <div class="sub__row">
                                        <div class="title">
                                            ID
                                        </div>
                                        <div class="value">
                                            <a :href="this.lroute('area.show', area.object_id)">{{ area.id }}</a>
                                        </div>
                                    </div>
                                    <div class="sub__row">
                                        <div class="title">
                                            Кадастровий номер:
                                        </div>
                                        <div class="value">
                                            {{ area.kadastr }}
                                        </div>
                                    </div>
                                    <div class="sub__row">
                                        <div class="title">
                                            Площа:
                                        </div>
                                        <div class="value">
                                            {{ area.area }} га
                                        </div>
                                    </div>
                                </div>
                                <div class="mobile__info-row">
                                    <div class="sub__row">
                                        <div class="title">
                                            Ціна за га:
                                        </div>
                                        <div class="value">
                                            {{ area.price_per_hectare }} грн.
                                        </div>
                                    </div>
                                    <div class="sub__row">
                                        <div class="title">
                                            Сума оренди:
                                        </div>
                                        <div class="value">
                                            8 486 грн.
                                        </div>
                                    </div>
                                </div>
                                <div class="mobile__info-row">
                                    <div class="sub__row">
                                        <div class="title">
                                            Термін оренди:
                                        </div>
                                        <div class="value">
                                            {{ area.lease_term }}
                                        </div>
                                    </div>
                                    <div class="sub__row">
                                        <div class="title">
                                            Статус:
                                        </div>
                                        <div class="value">
                                            {{ area.is_reserved ? 'Зарезервовано' : 'Не зарезервовано' }}
                                        </div>
                                    </div>
                                    <div class="sub__row">
                                        <div class="title">
                                            Орендар:
                                        </div>
                                        <div class="value">
                                            {{ area.tenant }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mobile__btns">
                                <template v-if="$global.auth">
                                    <a href=" " @click.prevent="sendRequest(area.id)" class="mobile__btn request">
                                        Подати заявку
                                    </a>
                                    <a href="" v-if="area.show_custom_price" @click.prevent="offerPrise(area.id)"
                                       class="mobile__btn propos">
                                        Запропонувати ціну
                                    </a>
                                </template>
                                <template v-else>
                                    <a href="#login_modal" uk-toggle class="mobile__btn request">
                                        Увійти
                                    </a>
                                    <a href="#register_modal" uk-toggle class="mobile__btn propos">
                                        Зареєструватися
                                    </a>
                                </template>
                            </div>

                        </div>
                    </div>
                </div>
            </Simplebar>
        </div>
        <div class="container" v-if="pagination?.total > filtersStore.perPage">
            <div class="counts__pagination">
                <div class="counts">
                    Показано {{ pagination.per_page }} з {{ pagination.total }} записів
                </div>
                <PaginationComponent @paginate="paginate" :per_page="to_view" :pagination="pagination"/>
            </div>
        </div>
    </div>


    <!--    TODO: MOBILE FILTERS    -->
    <div id="mobile_list_filters" class="mobile__list-filters" uk-offcanvas="overlay: true">
        <div class="uk-offcanvas-bar">
            <button class="uk-offcanvas-close" type="button">
                <Icon name="close"/>
            </button>
            <div class="header__count">
                <div class="header">
                    Фільтр
                </div>
                <div class="count">
                    Знайдено ділянок: <b>{{ this.pagination.total }}</b>
                </div>
            </div>
            <div class="search__wrapper-mobile" style="display: none;">
                <div class="search__icon">
                    <icon name="search"/>
                </div>
                <input type="text" class="search__input" v-model="search_query" v-debounce:400ms="searchHandler"
                       placeholder="Введіть кадастровий номер або ID ">
            </div>
            <MapListFilter/>
            <div class="btns__wrapper">
                <a href="" class="mobile__filter-action-btn uk-offcanvas-close apply">Фільтрувати</a>
                <a href="" class="mobile__filter-action-btn uk-offcanvas-close cancel disabled"
                   @click.prevent="resetFilters"
                >Скинути</a>
            </div>

        </div>
    </div>
    <OfferPriceModal/>
</template>

<style lang="scss">
.map__list-top {
    .counts__pagination {
        margin-top: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .counts {
            color: $black;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%; /* 14px */
        }

    }

    .active__filters {
        display: flex;
        align-items: center;
        gap: 10px;

        flex-wrap: wrap;

        &:has(.active__filters-item) {
            padding-bottom: 20px;
            border-bottom: 1px solid #D9D9D9;
            margin-bottom: 25px;
        }


        .active__filters-item {
            display: inline-flex;
            align-items: center;
            gap: 8px;
            color: #242424;
            text-align: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 140%; /* 16.8px */
            padding: 5px;
            border-radius: 5px;
            background-color: $grey;
            flex-wrap: wrap;

            .region__wrapper {
                display: inline-flex;
                align-items: center;
                gap: 8px;
            }

            .remove__btn {
                width: 18px;
                height: 18px;
                border-radius: 5px;
                background-color: $red;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .list__info {
        color: $black;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 12px */
        margin-bottom: 26px;
    }

    .list__head {
        display: grid;
        grid-template-columns: 150px 230px 1fr 215px;
        grid-template-rows: 1fr;
        grid-gap: 10px;
        margin-bottom: 30px;
        //grid-template-areas:"filter" "view" "sesarch" "page-toggle";
    }

    .view__item {
        // grid-area: view;
        padding: 0 20px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 15px;
        border: 1px solid rgba(36, 36, 36, 0.20);

        .show__items {
            display: grid;
            grid-template-columns: 22px 1fr;
            grid-gap: 10px;
            align-items: center;
            color: #000;
            background-color: transparent;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 100%; /* 14px */


        }

        .items__drop {

            align-self: center;

            .items__count {
                padding-right: 24px;
                color: #242424 !important;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%; /* 18px */
                position: relative;

                &:after {
                    transition: 0.3s;
                    content: '';
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 20px;
                    height: 20px;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='7' viewBox='0 0 14 7' fill='none'%3E%3Cpath d='M1.16797 1.5L6.35051 5.94218C6.725 6.26317 7.2776 6.26317 7.65209 5.94218L12.8346 1.5' stroke='%23242424' stroke-width='1.25' stroke-linecap='round'/%3E%3C/svg%3E");
                }

                &[aria-expanded="true"] {
                    &:after {
                        transform: translateY(-50%) rotateX(180deg);
                    }
                }
            }

            .view__list-drop {
                border-radius: 15px;
                border: 1px solid rgba(36, 36, 36, 0.20);
                background: #FFF;
                box-shadow: 2px 5px 15px 0px rgba(0, 0, 0, 0.10);
                width: fit-content;
                min-width: 20px;
                padding: 10px;

                ul {
                    display: flex;
                    flex-direction: column;

                    li {
                        padding: 3px 0;
                        cursor: pointer;
                        color: #242424;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 100%; /* 18px */
                        &:hover {
                            text-decoration: underline !important;
                        }
                    }
                }
            }
        }

    }

    .search__item {
        // grid-area: search;
        .search__wrapper {
            position: relative;

            .search__input {
                border-radius: 15px;
                border: 1px solid rgba(36, 36, 36, 0.20);
                height: 60px;
                max-width: 440px;
                width: 100%;
                color: $black;
                font-family: Montserrat, sans-serif !important;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 100%; /* 14px */
                padding: 10px 20px 10px 60px;

            }

            .icon {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 25px;

                svg {
                    width: 15px;
                    height: 15px;
                }
            }
        }
    }

    .map__list-toggle {
        // grid-area: page-toggle;
    }


    .filter__drop {
        //  grid-area: filter;

        .filter__btn {
            border-radius: 15px;
            background: #F2F2F2;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 22px;
            height: 60px;
            gap: 18px;
            width: fit-content;
            color: #000 !important;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 100%; /* 14px */
        }

        .filter__dropdown {
            width: 615px;
            border-radius: 15px;
            border: 1px solid rgba(36, 36, 36, 0.20);
            background: #FFF;
            box-shadow: 2px 5px 15px 0px rgba(0, 0, 0, 0.10);
            z-index: 10;


        }
    }

    .map__list-toggle {
        border-radius: 12px;
        background: #F2F2F2;
        padding: 5px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 5px;
        width: 215px;
        align-items: center;
        justify-content: center;

        .map__btn {
            border-radius: 10px;
            display: grid;
            grid-template-columns: 20px 1fr;
            grid-gap: 10px;
            align-items: center;
            justify-content: center;
            color: rgba(36, 36, 36, 0.50) !important;
            font-size: 12px;
            font-weight: 600;
            line-height: 100%; /* 12px */
            height: 45px;
            padding: 12px 11px;

            &.active {
                background-color: $black;
                color: white !important;
            }
        }
    }

    .list__wrapper {
        width: 90vw;
        max-width: 1800px;
        margin: 0 auto;

        .list {
            border-radius: 5px;
            border: 1px solid rgba(36, 36, 36, 0.20);
            background: rgba(249, 249, 249, 0.07);
            overflow: hidden;
            user-select: none;

            .list__row {
                display: grid;
                grid-template-columns: 0.46fr 0.66fr 0.8fr  1fr 0.486fr 0.713fr 0.578fr 0.719fr 0.45fr 0.55fr 0.784fr 0.675fr 0.416fr;
                border-bottom: 1px solid $white;

                &:nth-child(odd) {
                    background: $light-blue;

                }

                &:last-child {
                    border-bottom: none;
                }

                &.list__header {
                    .list__col {
                        background: #E1F1E8;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 5px;
                        cursor: pointer;
                        position: relative;

                        .reset__sort {
                            position: absolute;
                            top: 3px;
                            right: 3px;
                            width: 16px;
                            height: 16px;
                            border-radius: 5px;
                            background-color: $red;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }

                        .title {
                            color: #242424;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 100%; /* 12px */
                        }

                        .arrows {
                            display: flex;
                            flex-direction: column;
                            align-items: center;


                            .arr__top, .arr__bot {

                                color: rgba($color: #242424, $alpha: 0.5);

                                &.active {
                                    color: rgba($color: #242424, $alpha: 1);
                                }
                            }
                        }

                        &.asc {
                            .reset__sort {
                                display: flex !important;
                            }

                            .arrows .arr__top {
                                color: rgba($color: #242424, $alpha: 1);

                                svg {
                                    path {
                                        fill-opacity: 1;
                                    }
                                }
                            }
                        }

                        &.desc {
                            .reset__sort {
                                display: flex !important;
                            }

                            .arrows .arr__bot {
                                color: rgba($color: #242424, $alpha: 1);

                                svg {
                                    path {
                                        fill-opacity: 1;
                                    }
                                }
                            }
                        }
                    }

                }

                .list__col {
                    padding: 20px 10px;
                    border-right: 1px solid $white;
                    color: #242424;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 100%; /* 12px */
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    word-break: break-all;

                    b {
                        font-weight: 600;
                    }

                    a {
                        font-weight: 500;
                        color: $green !important;
                    }

                    &.without__row {
                        border-right: none;
                        padding-top: 5px;
                        padding-bottom: 5px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .action__bnt {

                    }

                    .action__drop {
                        padding: 15px;
                        border-radius: 10px;
                        background: #242424;
                        width: 210px;
                        box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.15);

                        .action__drop-wrapper {
                            display: flex;
                            flex-direction: column;
                            gap: 5px;
                        }

                        .action__btn-item {
                            padding: 14px;
                            border-radius: 50px;
                            border: 1px solid #FFF;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: #FFF !important;
                            text-align: center;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 100%;

                            &.white {
                                background-color: white;
                                color: #242424 !important;
                            }
                        }
                    }
                }

            }

        }
    }

}

.mobile__list-filters {
    &.uk-open {
        .uk-offcanvas-bar {
            left: 0;
        }
    }

    .search__wrapper-mobile {
        position: relative;
        display: block !important;
        margin-bottom: 30px;

        .search__icon {
            color: $black;
            position: absolute;
            left: 20px;
            top: 50%;
            transform: translateY(-50%);
        }

        .search__input {
            padding: 15px 15px 15px 46px;
            height: 45px;
            border-radius: 10px;
            border: 1px solid rgba(36, 36, 36, 0.20);
            width: 100%;
            color: rgba(36, 36, 36, 0.50);
            font-family: Montserrat, sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%; /* 12px */
        }
    }

    .uk-offcanvas-bar {
        background-color: white;
        padding: 25px;
        max-width: 375px !important;
        width: 375px;
        left: -375px;
        display: flex;
        flex-direction: column;


        .uk-offcanvas-close {
            color: $black;
            background-color: transparent;
        }

        .header__count {
            margin-bottom: 30px;

            .header {
                color: #242424;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 100%; /* 16px */
                margin-bottom: 10px;
            }

            .count {
                color: rgba(36, 36, 36, 0.80);
                font-size: 12px;
                font-weight: 500;
                line-height: 100%; /* 12px */
                b {
                    color: $black;
                    font-weight: 700;
                }
            }
        }

        .btns__wrapper {
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-top: auto;

            .mobile__filter-action-btn {
                @include black-btn(55px, 100%, 12px, 12px);
                font-size: 14px;
                position: relative;
                left: initial;
                top: initial;

                &.cancel {
                    @include transparent-btn(55px, 100%, 12px, 12px);
                    font-size: 14px;
                }

                &.disabled {
                    border-color: rgba(36, 36, 36, 0.50);
                    color: rgba(36, 36, 36, 0.50) !important;

                }
            }

        }

    }
}

@media screen and (max-width: 1750px) {
    .map__list-top {
        .list__wrapper {
            width: 100%;
            padding: 0 30px;

            .list {
                min-width: 1600px;
            }
        }
    }
}

@media screen and (max-width: $breakpoint-df) {
    .map__list-top {
        .list__head {
            grid-template-columns: repeat( 4, 1fr);
            grid-template-areas:
                "filter view view toggle"
                "search search search search";

            .filter__drop {
                grid-area: filter;

                .filter__btn {
                    width: 100%;
                }
            }

            .view__item {
                grid-area: view;
            }

            .search__item {
                grid-area: search;

                .search__wrapper {
                    .search__input {
                        max-width: 100%;
                    }
                }
            }

            .map__list-toggle {
                grid-area: toggle;
            }
        }

        .list__wrapper {
            padding: 0 15px;
        }
    }
}

@media screen and (max-width: $breakpoint-md) {
    .map__list-top {
        .counts__pagination {
            margin-top: 30px;
            gap: 30px;
            flex-direction: column;
            align-items: flex-start;
        }

        .list__info {
            display: none;
        }

        .list__head {
            grid-template-columns: 1fr 120px 55px;
            grid-template-areas:
                "view toggle toggle "
                "mob-filter mob-filter search-mob ";

            .filter__drop {
                display: none;
            }

            .filter__btn-mobile {
                grid-area: mob-filter;
                display: flex !important;
                border-radius: 10px;
                background: #F2F2F2;
                align-items: center;
                justify-content: center;
                padding: 0 15px;
                height: 50px;
                gap: 10px;
                width: 100%;
                color: #000 !important;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 100%;
            }

            .view__item {
                border-radius: 10px;
                height: 50px;
                padding: 0 15px;

                .show__items {
                    font-size: 12px;
                    line-height: 1;
                }

                .items__drop {
                    .items__count {
                        font-size: 14px;
                    }

                    .view__list-drop {
                        width: fit-content;
                        min-width: 10px;
                        padding: 10px;

                        ul li {
                            font-size: 12px;
                        }
                    }
                }
            }

            .map__list-toggle {
                width: 100%;
                border-radius: 10px;

                .map__btn {
                    height: 40px;
                    padding: 5px;
                }
            }

            .search__item {
                display: none;
            }

            .search__mob {
                grid-area: search-mob;
                display: flex !important;
                align-items: center;
                justify-content: center;
                border-radius: 10px;
                border: 1px solid rgba(36, 36, 36, 0.20);

            }
        }

        .list__wrapper {
            .list {
                min-width: initial;

                .list__row {
                    grid-template-columns:1fr 0.775fr 0.7fr 38px;

                    &.list__header {
                        .list__col {
                            flex-direction: row;
                        }
                    }

                    .list__col {
                        padding: 20px 10px;
                        word-break: break-all;
                        flex-direction: column;

                        .show__mobile {
                            display: flex !important;
                        }

                        .title {
                            font-size: 11px !important;
                        }

                        .title {
                            .desk {
                                display: none;
                            }

                            .mob {
                                display: block !important;
                            }
                        }

                        &.mobile__action {
                            display: flex !important;

                            .mob__action-btn {
                                svg {
                                    transition: 0.3s;
                                }

                                &.open {
                                    svg {
                                        transform: rotateX(180deg);
                                    }
                                }

                            }
                        }

                        &.hide__mobile {
                            display: none;
                        }
                    }

                    .mobile__info-section {
                        display: flex !important;
                        grid-column-start: 1;
                        grid-column-end: 5;
                        padding: 0;
                        flex-direction: column;
                        border-top: 1px solid rgba(36, 36, 36, 0.20);
                        max-height: 0;
                        overflow: hidden;
                        visibility: hidden;
                        opacity: 0;
                        transition: all 0.5s linear;

                        &.open {
                            padding: 20px 0;
                            max-height: 500px;
                            visibility: visible;
                            opacity: 1;
                            transition: all 0.5s linear;
                        }

                        .mobile__rows {
                            .mobile__info-row {
                                padding: 15px;
                                border-bottom: 1px solid rgba(36, 36, 36, 0.20);
                                display: flex;
                                flex-direction: column;
                                gap: 12px;

                                &:last-child {
                                    border-bottom: none;
                                }

                                &:first-child {
                                    padding-top: 0;
                                }

                                .sub__row {
                                    display: flex;
                                    justify-content: space-between;

                                    .title {
                                        color: #242424;
                                        font-size: 12px;
                                        font-style: normal;
                                        font-weight: 700;
                                        line-height: 100%; /* 12px */
                                    }

                                    .value {
                                        color: #242424;
                                        font-family: Montserrat;
                                        font-size: 12px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: 100%; /* 12px */
                                        a {
                                            text-decoration-line: underline !important;
                                        }
                                    }
                                }


                            }
                        }

                        .mobile__btns {
                            display: flex;
                            flex-direction: column;
                            gap: 10px;
                            padding: 0 15px;
                            margin-top: 5px;

                            .mobile__btn {
                                @include black-btn(40px, 100%, 14px, 14px);
                                font-size: 12px;

                                &.propos {
                                    @include transparent-btn(40px, 100%, 14px, 14px);
                                    font-size: 12px;
                                }
                            }
                        }
                    }

                }
            }
        }
    }
}

@media screen and (max-width: $breakpoint-xs) {
    .mobile__list-filters {
        &.uk-open {

        }

        .uk-offcanvas-bar {
            max-width: 100% !important;
            width: 100%;
            left: -100%;

        }

    }
    .map__list-top {
        .list__head {
            .view__item {
                .show__items {
                    grid-template-columns: 1fr;

                    .icon {
                        display: none;

                    }
                }

                .items__drop {
                    font-size: 12px;

                    .items__count {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}

@media (max-width: $breakpoint-sm) {
    .map__list-top .active__filters .active__filters-item {
        width: 100%;
        display: flex;
        justify-content: space-between;

        &.regions {
            flex-direction: column;
            align-items: flex-start;

            .region__wrapper {
                display: flex;
                width: 100%;
                justify-content: space-between;
            }
        }
    }
}
</style>
