<script>

import Slider from '@vueform/slider'
import Icon from "@/components/icon.vue";
import MultiselectComponent from "@/components/partial/multiselect-component.vue";
import SingleSelectComponent from "@/components/partial/single-select-component.vue";
import {useFiltersStore} from "@/store/filter.js";

export default {
    name: "map-list-filter",
    setup() {
        const filtersStore = useFiltersStore()
        return { filtersStore }
    },
    components: {
        SingleSelectComponent,
        MultiselectComponent,
        Icon,
        Slider,
    },
    created() {

    },
    data() {
        return {
            coll_count: 23,
            is_mobile: false
        }
    },
    methods:{

        setRange(data){
                return
            // ! Я ХЗ КАК ТУТ ЧТО СЧИТАТЬ , ДОЛЖНІ СЧИТАТЬ СТОЛБИКИ
            let collist = event.target.closest('.form__row').querySelectorAll('.column');
            let activeCountMax =  Math.floor((this.coll_count*data[1])/this.rent_max)
            let activeCountMin =  Math.round((this.coll_count*data[0])/this.rent_max)
            console.log(activeCountMin,activeCountMax)
            collist.forEach((el, idx)=>{

                if( activeCountMin<= idx && idx <= activeCountMax ){
                    el.classList.add("active")
                } else{
                    el.classList.remove("active")
                }
            })
        },
        emitUpdateFilter(){
            this.$emit('update-filter', {
                region: this.region,
            })
        }
    }
}
</script>

<template>
    <form class="map__list-filter">
        <div class="row__wrapper">
            <div class="form__row">
                <div class="row__title">
                    Статус
                </div>
                <div class="radio__btns">
                    <div class="radio__wrapper">
                        <label>
                            <input type="radio" class="uk-radio radio" v-model="filtersStore.availability"
                                   value="not_available"
                            >
                            Зарезервовано
                        </label>
                    </div>
                    <div class="radio__wrapper">
                        <label>
                            <input type="radio" class="uk-radio radio" v-model="filtersStore.availability"
                                   value="available">
                            Не зарезервовано
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row__wrapper">
            <div class="form__row">
                <div class="row__title">
                    Регіон (вибір області)
                </div>
                <MultiselectComponent :data="filtersStore.regions" v-model="filtersStore.region"></MultiselectComponent>
            </div>
        </div>
        <div class="row__wrapper">
            <div class="form__row">
                <div class="row__title">
                    Термін оренди
                </div>
                <SingleSelectComponent :data="filtersStore.lease_terms" v-model="filtersStore.lease_term"></SingleSelectComponent>
            </div>
        </div>
        <div class="row__wrapper">
            <div class="form__row">
                <div class="row__title">
                    Орендар
                </div>
                <SingleSelectComponent  :data="filtersStore.tenants" v-model="filtersStore.tenant"></SingleSelectComponent>
            </div>
        </div>
        <div class="row__wrapper col__2">
            <div class="form__row">
                <div class="row__title">
                    Прибутковість, %
                </div>
                <!--                        height max 18px = 100%-->
                <div class="columns__wrapper">
                    <div class="column" v-for="i in coll_count"
                         :style="{height: `${Math.floor(Math.random() * 19)}px`}" :key="i">
                    </div>
                </div>
                <div class="range__slider-wrapper">
                    <Slider
                        v-model="filtersStore.rent"
                        showTooltip="drag"
                        :step="1"
                        :min="filtersStore.rent_min"
                        :max="filtersStore.rent_max"
                        @update=""
                    />
                </div>
                <div class="profitability__values">
                    <input type="number" class="value" v-model="filtersStore.rent[0]" step="0.1" :placeholder="filtersStore.rent_min" :min="filtersStore.rent_min">
                    <input type="number" class="value" v-model="filtersStore.rent[1]" step="0.1" :placeholder="filtersStore.rent_max" :max="filtersStore.rent_max">

                </div>
            </div>
            <div class="form__row">
                <div class="row__title">
                    Площа, га
                </div>
                <!--                        height max 18px = 100%-->
                <div class="columns__wrapper">
                    <div class="column" v-for="i in coll_count"
                         :style="{height: `${Math.floor(Math.random() * 19)}px`}" :key="i">
                    </div>
                </div>
                <div class="range__slider-wrapper">
                    <Slider
                        v-model="filtersStore.area"
                        showTooltip="drag"
                        :step="1"
                        :min="filtersStore.area_min"
                        :max="filtersStore.area_max"
                        @update=""
                    />
                </div>
                <div class="profitability__values">
                    <input type="number" class="value" v-model="filtersStore.area[0]" step="0.1" :min="filtersStore.area_min">
                    <input type="number" class="value" v-model="filtersStore.area[1]" step="0.1" :max="filtersStore.area_max">

                </div>
            </div>
        </div>
        <div class="row__wrapper col__2">

            <div class="form__row">
                <div class="row__title">
                    Ціна, грн
                </div>
                <!--                        height max 18px = 100%-->
                <div class="columns__wrapper">
                    <div class="column" v-for="i in coll_count"
                         :style="{height: `${Math.floor(Math.random() * 19)}px`}" :key="i">
                    </div>
                </div>
                <div class="range__slider-wrapper">
                    <Slider
                        v-model="filtersStore.land_price"
                        showTooltip="drag"
                        :step="1000"
                        :min="filtersStore.land_price_min"
                        :max="filtersStore.land_price_max"
                        @update=""
                    />
                </div>
                <div class="profitability__values">
                    <input type="number" class="value" v-model="filtersStore.land_price[0]" :min="filtersStore.land_price_min">
                    <input type="number" class="value" v-model="filtersStore.land_price[1]" :max="filtersStore.land_price_max">

                </div>
            </div>
            <div class="form__row">
                <div class="row__title">
                    Ціна за 1 га., грн
                </div>
                <!--                        height max 18px = 100%-->
                <div class="columns__wrapper">
                    <div class="column" v-for="i in coll_count"
                         :style="{height: `${Math.floor(Math.random() * 19)}px`}" :key="i">
                    </div>
                </div>
                <div class="range__slider-wrapper">
                    <Slider
                        v-model="filtersStore.price_per_hectare"
                        showTooltip="drag"
                        :step="1000"
                        :min="filtersStore.price_per_hectare_min"
                        :max="filtersStore.price_per_hectare_max"
                        @update=""
                    />
                </div>
                <div class="profitability__values">
                    <input type="number" class="value" v-model="filtersStore.price_per_hectare[0]" :min="filtersStore.price_per_hectare_min">
                    <input type="number" class="value" v-model="filtersStore.price_per_hectare[1]" :max="filtersStore.price_per_hectare_max">

                </div>
            </div>
        </div>
    </form>

</template>

<style lang="scss">
.map__list-filter {
    display: flex;
    flex-direction: column;
    gap: 25px;

    .row__wrapper {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 25px;

        &.col__2 {
            grid-template-columns: 1fr 1fr;
        }
    }

    .input__wrapper {
        position: relative;

        .search__icon {
            position: absolute;
            left: 20px;
            top: 50%;
            transform: translateY(-50%);
        }

        .search__input {
            padding: 15px 15px 15px 46px;
            height: 45px;
            border-radius: 10px;
            border: 1px solid rgba(36, 36, 36, 0.20);
            width: 100%;
            color: rgba(36, 36, 36, 0.50);
            font-family: Montserrat, sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%; /* 12px */
        }
    }

    .row__title {
        color: rgba(36, 36, 36, 0.70);
        margin-bottom: 16px;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 12px */
    }

    .radio__btns {
        display: flex;
        align-items: center;
        gap: 20px;

        .radio__wrapper {
            label {
                color: #242424;
                text-align: center;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 140%; /* 19.6px */
                color: $light-grey;
                cursor: pointer;
                &:has(.radio:checked) {
                    color: $black;
                }
            }

            .radio {
                width: 20px;
                height: 20px;
                margin-right: 4px;
                border: 1px solid rgba(51, 51, 51, 0.20);

                &:checked {
                    border: 1px solid $green;
                    background-size: 17px;
                    background: white url("data:image/svg+xml,%0A%3Csvg width='15' height='15' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Group 31'%3E%3Ccircle id='Ellipse 44' cx='10' cy='10' r='7' fill='%2336A363'/%3E%3C/g%3E%3C/svg%3E%0A") no-repeat center/contain;
                }
            }
        }
    }

    .range__slider-wrapper {
        padding: 0 ;
        margin-bottom: 18px;
        .slider-tooltip{
            font-size: 12px;
        }

        .slider-target {
            height: 3px;

            .slider-connects {
                height: 3px;
                background-color: #F3F3F3;

                .slider-connect {
                    background-color: $black;
                }
            }

            .slider-origin {
                .slider-handle {
                    width: 20px;
                    height: 20px;
                    border: 2px solid white;
                    box-shadow: (1px 2px 8px rgba(0, 0, 0, 0.15));
                    top: calc((20px - 6px) / 2 * -1 + -1px);
                    border-radius: 6px;
                    background-color: black;
                    background-repeat: no-repeat;
                    background-size: 8px;
                    background-position: center center;
                    background-image: url("data:image/svg+xml,%3Csvg width='8' height='10' viewBox='0 0 8 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L1 9' stroke='white' stroke-width='0.5' stroke-linecap='round'/%3E%3Cpath d='M4 1L4 9' stroke='white' stroke-width='0.5' stroke-linecap='round'/%3E%3Cpath d='M7 1L7 9' stroke='white' stroke-width='0.5' stroke-linecap='round'/%3E%3C/svg%3E%0A");
                }
            }
        }
    }

    .profitability__values {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .value {
            height: 32px;
            border-radius: 8px;
            border: 1px solid rgba(36, 36, 36, 0.15);
            width: 110px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            color: rgba(36, 36, 36, 0.80);
            font-family: Montserrat, sans-serif !important;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                /* display: none; <- Crashes Chrome on hover */
                -webkit-appearance: none;
                margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
            }
        }
    }



    .columns__wrapper {
        height: 18px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        gap: 3px;
        position: relative;
        margin-bottom: 2px;

        .column {
            border-radius: 2px;
            background: #F3F3F3;
            height: 0;
            width: 100%;
            &.active{
                background-color: $green;
            }

        }
    }
}

@media screen and (max-width: $breakpoint-md){
    .map__list-filter {
        margin-bottom: 50px;
        .radio__btns{
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
        }
      .row__wrapper{
            &.col__2{
                grid-template-columns: 1fr;
            }
        }
    }
}
</style>
