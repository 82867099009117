import {defineStore} from 'pinia'
import axios from "axios";

export const useFiltersStore = defineStore('filters', {
    state: () => ({
        availability: null,// 'not_available', available
        region: null,
        regions: [],
        lease_terms: [],
        lease_term: null,
        tenants: [],
        tenant: null,
        rent: [null, null],
        rent_min: 0,
        rent_max: 0,
        area: [null, null],
        area_min: 0,
        area_max: 0,
        land_price: [null, null],
        land_price_min: 0,
        land_price_max: 0,
        price_per_hectare: [null, null],
        price_per_hectare_min: 0,
        price_per_hectare_max: 0,
        perPage: 25
    }),
    actions: {
        //! region_id[]=1&region_id=2&...&area[from]=1&area[to]=10.2234&...
        getFiltersData() {
            axios.get(route('api.areas.filters-data')).then(res => {
                this.$patch({
                    regions: res.data.data.regions ?? [],
                    lease_terms: res.data.data.lease_terms ?? [],
                    tenants: res.data.data.tenants ?? [],
                    rent_min: Math.floor(+res.data.data.rentMinMax[0]['min']) ?? 0,
                    rent_max: Math.ceil(+res.data.data.rentMinMax[0]['max']) ?? 0,
                    area_min: Math.floor(+res.data.data.areaMinMax[0]['min']) ?? 0,
                    area_max: Math.ceil(+res.data.data.areaMinMax[0]['max']) ?? 0,
                    land_price_min: +res.data.data.landPriceMinMax[0]['min'] ?? 0,
                    land_price_max: +res.data.data.landPriceMinMax[0]['max'] ?? 0,
                    price_per_hectare_min: +res.data.data.pricePerHectareMinMax[0]['min'] ?? 0,
                    price_per_hectare_max: +res.data.data.pricePerHectareMinMax[0]['max'] ?? 0,
                })
                if (this.rent[0] == this.rent_min  || this.rent[0]==null  && this.rent[1] == this.rent_max || this.rent[1]==null) {
                    this.$patch({
                        rent: [this.rent_min, this.rent_max],
                    })
                }
                if (this.area[0] == this.area_min || this.area[0]==null && this.area[1] == this.area_max || this.area[1] == null) {
                    this.$patch({
                        area: [this.area_min, this.area_max],
                    })
                }
                if (this.land_price[0] == this.land_price_min || this.land_price[0] == null  && this.land_price[1] == this.land_price_max || this.land_price[1] == null) {
                    this.$patch({
                        land_price: [this.land_price_min, this.land_price_max],
                    })
                }
                if (this.price_per_hectare[0] == this.price_per_hectare_min || this.price_per_hectare[0]==null && this.price_per_hectare[1] == this.price_per_hectare_max || this.price_per_hectare[1] == null) {
                    this.$patch({
                        price_per_hectare: [this.price_per_hectare_min, this.price_per_hectare_max],
                    })
                }
                console.log(res.data.data)
            }).catch(err => {
                console.log('%c get filter data error:', "color:white; background:red;", err.message);
            })
        },
        clearRegion(id) {
            this.region = this.region.filter(el => el.id != id)
        },
        resetFilters() {
            axios.get(route('api.areas.filters-data')).then(res => {
                    this.availability = null
                    this.lease_term = null
                    this.tenant = null
                    this.region = null
                    this.regions= res.data.data.regions ?? []
                    this.lease_terms= res.data.data.lease_terms ?? []
                    this.tenants= res.data.data.tenants ?? []
                    this.rent_min= +res.data.data.rentMinMax[0]['min'] ?? 0
                    this.rent_max= +res.data.data.rentMinMax[0]['max'] ?? 0
                    this.area_min= +res.data.data.areaMinMax[0]['min'] ?? 0
                    this.area_max= +res.data.data.areaMinMax[0]['max'] ?? 0
                    this.land_price_min= +res.data.data.landPriceMinMax[0]['min'] ?? 0
                    this.land_price_max= +res.data.data.landPriceMinMax[0]['max'] ?? 0
                    this.price_per_hectare_min= +res.data.data.pricePerHectareMinMax[0]['min'] ?? 0
                    this.price_per_hectare_max= +res.data.data.pricePerHectareMinMax[0]['max'] ?? 0
                    this.rent= [this.rent_min, this.rent_max]
                    this.area= [this.area_min, this.area_max]
                    this.land_price= [this.land_price_min, this.land_price_max]
                    this.price_per_hectare= [this.price_per_hectare_min, this.price_per_hectare_max]
            });
        }

    },
    getters: {},
    persist: {
        beforeRestore: (ctx) => {
            console.log(ctx)
            console.log(`about to restore '${ctx.store.$id}'`)
        }
    },
})
