<script>
import Icon from '@/components/icon.vue'
import axios from "axios";
import SpinnerForBtn from "@/components/partial/spinner-for-btn.vue";
import UIkit from "uikit";

export default {
    name: "polygon-modal",
    props: {
        area_info: {
            default: () => {
            }
        }
    },
    components: {
        SpinnerForBtn,
        Icon,
    },
    data() {
        return {
            is_loading: false,
        }
    },

    methods: {
        offerPrise(item) {
            this.$mitt.emit('offer-price', this.area_info.id)
        },
        sendRequest(id) {
            this.is_loading = true;
            axios.post(this.lroute('area.request'), {id: id}).then(res => {
                this.is_loading = false;
                this.$mitt.emit('request-success', this.area_info.id)
            }).catch(e=>{
                this.is_loading = false;
                UIkit.notification({message: 'Трапилась помилка при подачі заявки', status: 'danger'})
            })
        },
        goToArea(id) {
            window.location.href = this.lroute('area.show', id);
        }
    }
}
</script>

<template>
    <div class="uk-flex-top polygon__modal" uk-modal ref="pol_mod">
        <div class="uk-modal-dialog uk-margin-auto-vertical">
            <button class="uk-modal-close-outside" type="button">
                <icon name="close-small" width="20" height="20"/>
            </button>
            <div class="pol__header">
                <icon name="marker" width="24" height="24" class="icon"/>
                <span>
                   {{ area_info?.district }}
               </span>
            </div>
            <div class="pol__data">
                <div class="pol_data-item">
                    <div class="pol__b">
                        {{ area_info?.land_price }} грн.
                    </div>
                    <div class="pol__l">
                        ціна ділянки
                    </div>
                </div>
                <div class="pol_data-item">
                    <div class="pol__b">
                        {{ area_info?.price_per_hectare }} грн.
                    </div>
                    <div class="pol__l">
                        ціна за га.
                    </div>
                </div>
                <div class="pol_data-item">
                    <div class="pol__b">
                        {{ area_info?.rent }}%
                    </div>
                    <div class="pol__l">
                        прибутковість
                    </div>
                </div>
            </div>
            <div class="pol__rows">
                <div class="pol__row-section">
                    <div class="pol__row">
                        <div class="row__title">
                            ID
                        </div>
                        <div class="row__value">
                            <a href="#" @click.prevent="goToArea(area_info.object_id)">{{ area_info?.id }}</a>
                        </div>

                    </div>
                    <div class="pol__row">
                        <div class="row__title">
                            Кадастровий номер
                        </div>
                        <div class="row__value">
                            {{ area_info?.kadastr }}
                        </div>

                    </div>
                    <div class="pol__row">
                        <div class="row__title">
                            Площа
                        </div>
                        <div class="row__value">
                            {{ area_info?.area }} га
                        </div>

                    </div>
                </div>
                <div class="pol__row-section">
                    <div class="pol__row">
                        <div class="row__title">
                            Термін оренди
                        </div>
                        <div class="row__value">
                            до {{ area_info?.lease_term }} року
                        </div>

                    </div>
                    <div class="pol__row">
                        <div class="row__title">
                            Чистий дохід від оренди
                        </div>
                        <div class="row__value">
                            {{ area_info?.net_rental_income }} грн.
                        </div>

                    </div>
                </div>
                <div class="pol__row-section">
                    <div class="pol__row">
                        <div class="row__title">
                            Нотаріальні послуги
                        </div>
                        <div class="row__value">
                            {{ area_info?.initial_notary_fee }} грн. - {{ area_info?.max_notary_fee }} грн.
                        </div>

                    </div>
                    <div class="pol__row">
                        <div class="row__title">
                            Комісія платформи
                        </div>
                        <div class="row__value">
                            {{ area_info?.platform_comission }} %
                        </div>

                    </div>
                    <div class="pol__row">
                        <div class="row__title">
                            Орендар
                        </div>
                        <div class="row__value">
                            {{ area_info?.tenant }}
                        </div>

                    </div>
                </div>
            </div>
            <template v-if="area_info?.is_reserved ">
                В процесі продажу
            </template>
            <template v-else>
                <div class="pol__btns" v-if="$global.auth">
                    <a href="" @click.prevent="sendRequest(area_info.id)" class="send__request">
                        <template v-if="is_loading">
                            <SpinnerForBtn :is_loading="is_loading"></SpinnerForBtn>
                        </template>
                        <template v-else>
                            Подати заявку
                        </template>
                    </a>
                    <a v-if="area_info?.show_custom_price" href="" @click.prevent="offerPrise()" class="send__price">
                        Запропонувати ціну
                    </a>
                </div>
                <div class="pol_msg" v-else>
                    *Для подачі заявки <a href="#login_modal" uk-toggle>увійдіть</a> або <a uk-toggle
                                                                                            href="#register_modal">зареєструйтесь</a>
                </div>
            </template>

        </div>
    </div>
</template>

<style lang="scss">
.polygon__modal {
    .uk-modal-dialog {
        padding: 30px;
        border-radius: 20px;
        background: #FFF;
        max-width: 520px !important;
        width: 100%;
        box-shadow: 2px 4px 15px 0px rgba(0, 0, 0, 0.10);

        .uk-modal-close-outside {
            top: -5px;
            width: 45px;
            height: 45px;
            background-color: white;
            border-radius: 100%;
            color: $black;

        }

        .pol__header {
            color: #242424;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%; /* 16px */
            display: grid;
            grid-template-columns: 24px 1fr;
            grid-gap: 10px;
            align-items: center;
            padding-right: 20px;
            margin-bottom: 25px;

        }

        .pol__data {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 5px;

            .pol_data-item {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 10px;
                padding: 20px 16px;
                background: $light-blue;
                border-radius: 10px;

                .pol__b {
                    color: $green;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 100%; /* 16px */
                }

                .pol__l {
                    color: $dark-gray;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 100%; /* 12px */
                }

            }


        }

        .pol__rows {
            margin-bottom: 10px;

            .pol__row-section {
                padding: 20px 0;
                border-bottom: 1px solid rgba(36, 36, 36, 0.15);
                display: flex;
                flex-direction: column;
                gap: 15px;

                &:last-child {
                    border-bottom: none;
                }
            }

            .pol__row {

                display: flex;
                justify-content: space-between;


                .row__title {
                    color: $black;
                    font-size: 12px;
                    font-weight: 700;
                    line-height: 100%; /* 12px */
                }

                .row__value {
                    color: #242424;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 100%;

                    a {
                        color: black !important;
                        text-decoration: underline !important;
                    }
                }
            }

        }

        .pol__btns {
            display: flex;

            gap: 10px;
            margin-bottom: 20px;

            .send__request {
                position: relative;
                overflow: hidden;
                @include black-btn(55px, 100%, 9px, 12px);
            }

            .send__price {
                @include transparent-btn(55px, 100%, 9px, 12px);
            }
        }

        .pol_msg {
            text-align: center;
            color: rgba(36, 36, 36, 0.70);
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%; /* 12px */
            a {
                font-weight: 600;
                color: $green;

                &:hover {
                    text-decoration: underline !important;
                }
            }
        }
    }
}

@media screen and (max-width: 576px) {
    .polygon__modal {
        .uk-modal-dialog {
            border-radius: 15px;
            max-width: 335px !important;
            padding: 24px 15px;

            .uk-modal-close-default {
                right: 15px;
            }

            .pol__header {
                margin-bottom: 15px;
                font-size: 12px;
                line-height: 130%;
            }

            .pol__data {
                margin-bottom: 10px;
                grid-template-columns: initial;
                grid-template-rows: initial;
                grid-gap: initial;
                gap: 10px;
                display: flex;
                flex-direction: column;

                .pol_data-item {
                    border-right: none;
                    padding: 15px;
                    flex-direction: row-reverse;
                    gap: initial;
                    justify-content: space-between;
                    align-items: center;

                    .pol__b {
                        font-size: 12px;
                    }
                }
            }

            .pol__rows {
                margin-bottom: 8px;

                .pol__row-section {
                    padding: 12px 0;
                }
            }

            .pol__btns {
                gap: 5px;
                flex-wrap: wrap;
                margin-bottom: 15px;

                .send__request, .send__price {
                    height: 38px;
                    font-size: 12px;
                }
            }

            .pol_msg {
                font-size: 11px;
            }
        }
    }

}

</style>
