<script>
import {useFiltersStore} from "@/store/filter.js";

export default {
    name: "filters-result",
    setup() {
        const filtersStore = useFiltersStore()
        return {filtersStore}
    },
}
</script>

<template>
    <div class="active__filters">
        <div class="active__filters-item" v-if="this.filtersStore.availability">
            {{ this.filtersStore.availability == 'available' ? 'Не зарезервовано' : 'Зарезервовано' }}
            <button @click="this.filtersStore.availability = null" type="button" class="remove__btn">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                    <path
                        d="M6.52984 6.00023L9.64234 2.89242C9.70214 2.81956 9.7327 2.72706 9.72808 2.63291C9.72345 2.53877 9.68398 2.44971 9.61733 2.38306C9.55068 2.31641 9.46162 2.27693 9.36747 2.27231C9.27333 2.26768 9.18083 2.29824 9.10797 2.35804L6.00016 5.47054L2.89234 2.35804C2.81948 2.29824 2.72698 2.26768 2.63284 2.27231C2.53869 2.27693 2.44964 2.31641 2.38299 2.38306C2.31633 2.44971 2.27686 2.53877 2.27223 2.63291C2.26761 2.72706 2.29817 2.81956 2.35797 2.89242L5.47047 6.00023L2.35797 9.10804C2.28755 9.17914 2.24805 9.27516 2.24805 9.37523C2.24805 9.4753 2.28755 9.57132 2.35797 9.64242C2.42965 9.71173 2.52545 9.75047 2.62516 9.75047C2.72486 9.75047 2.82067 9.71173 2.89234 9.64242L6.00016 6.52992L9.10797 9.64242C9.17965 9.71173 9.27545 9.75047 9.37516 9.75047C9.47486 9.75047 9.57067 9.71173 9.64234 9.64242C9.71276 9.57132 9.75226 9.4753 9.75226 9.37523C9.75226 9.27516 9.71276 9.17914 9.64234 9.10804L6.52984 6.00023Z"
                        fill="white"/>
                </svg>
            </button>
        </div>
        <div class="active__filters-item regions" v-if="this.filtersStore.region?.length">
            <div class="title">
                Регіон:
            </div>
            <div class="region__wrapper" v-for="region in this.filtersStore.region">
                {{ region.title }}
                <button @click="this.filtersStore.clearRegion(region.id)" type="button" class="remove__btn">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"
                         fill="none">
                        <path
                            d="M6.52984 6.00023L9.64234 2.89242C9.70214 2.81956 9.7327 2.72706 9.72808 2.63291C9.72345 2.53877 9.68398 2.44971 9.61733 2.38306C9.55068 2.31641 9.46162 2.27693 9.36747 2.27231C9.27333 2.26768 9.18083 2.29824 9.10797 2.35804L6.00016 5.47054L2.89234 2.35804C2.81948 2.29824 2.72698 2.26768 2.63284 2.27231C2.53869 2.27693 2.44964 2.31641 2.38299 2.38306C2.31633 2.44971 2.27686 2.53877 2.27223 2.63291C2.26761 2.72706 2.29817 2.81956 2.35797 2.89242L5.47047 6.00023L2.35797 9.10804C2.28755 9.17914 2.24805 9.27516 2.24805 9.37523C2.24805 9.4753 2.28755 9.57132 2.35797 9.64242C2.42965 9.71173 2.52545 9.75047 2.62516 9.75047C2.72486 9.75047 2.82067 9.71173 2.89234 9.64242L6.00016 6.52992L9.10797 9.64242C9.17965 9.71173 9.27545 9.75047 9.37516 9.75047C9.47486 9.75047 9.57067 9.71173 9.64234 9.64242C9.71276 9.57132 9.75226 9.4753 9.75226 9.37523C9.75226 9.27516 9.71276 9.17914 9.64234 9.10804L6.52984 6.00023Z"
                            fill="white"/>
                    </svg>
                </button>
            </div>
        </div>

        <div class="active__filters-item" v-if="this.filtersStore.lease_term?.title">
            Термін оренди: {{ this.filtersStore.lease_term.title }}
            <button @click="this.filtersStore.lease_term = null" type="button" class="remove__btn">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                    <path
                        d="M6.52984 6.00023L9.64234 2.89242C9.70214 2.81956 9.7327 2.72706 9.72808 2.63291C9.72345 2.53877 9.68398 2.44971 9.61733 2.38306C9.55068 2.31641 9.46162 2.27693 9.36747 2.27231C9.27333 2.26768 9.18083 2.29824 9.10797 2.35804L6.00016 5.47054L2.89234 2.35804C2.81948 2.29824 2.72698 2.26768 2.63284 2.27231C2.53869 2.27693 2.44964 2.31641 2.38299 2.38306C2.31633 2.44971 2.27686 2.53877 2.27223 2.63291C2.26761 2.72706 2.29817 2.81956 2.35797 2.89242L5.47047 6.00023L2.35797 9.10804C2.28755 9.17914 2.24805 9.27516 2.24805 9.37523C2.24805 9.4753 2.28755 9.57132 2.35797 9.64242C2.42965 9.71173 2.52545 9.75047 2.62516 9.75047C2.72486 9.75047 2.82067 9.71173 2.89234 9.64242L6.00016 6.52992L9.10797 9.64242C9.17965 9.71173 9.27545 9.75047 9.37516 9.75047C9.47486 9.75047 9.57067 9.71173 9.64234 9.64242C9.71276 9.57132 9.75226 9.4753 9.75226 9.37523C9.75226 9.27516 9.71276 9.17914 9.64234 9.10804L6.52984 6.00023Z"
                        fill="white"/>
                </svg>
            </button>
        </div>
        <div class="active__filters-item" v-if="this.filtersStore.tenant">
            Орендар: {{ this.filtersStore.tenant.title }}
            <button @click="this.filtersStore.tenant = null" type="button" class="remove__btn">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                    <path
                        d="M6.52984 6.00023L9.64234 2.89242C9.70214 2.81956 9.7327 2.72706 9.72808 2.63291C9.72345 2.53877 9.68398 2.44971 9.61733 2.38306C9.55068 2.31641 9.46162 2.27693 9.36747 2.27231C9.27333 2.26768 9.18083 2.29824 9.10797 2.35804L6.00016 5.47054L2.89234 2.35804C2.81948 2.29824 2.72698 2.26768 2.63284 2.27231C2.53869 2.27693 2.44964 2.31641 2.38299 2.38306C2.31633 2.44971 2.27686 2.53877 2.27223 2.63291C2.26761 2.72706 2.29817 2.81956 2.35797 2.89242L5.47047 6.00023L2.35797 9.10804C2.28755 9.17914 2.24805 9.27516 2.24805 9.37523C2.24805 9.4753 2.28755 9.57132 2.35797 9.64242C2.42965 9.71173 2.52545 9.75047 2.62516 9.75047C2.72486 9.75047 2.82067 9.71173 2.89234 9.64242L6.00016 6.52992L9.10797 9.64242C9.17965 9.71173 9.27545 9.75047 9.37516 9.75047C9.47486 9.75047 9.57067 9.71173 9.64234 9.64242C9.71276 9.57132 9.75226 9.4753 9.75226 9.37523C9.75226 9.27516 9.71276 9.17914 9.64234 9.10804L6.52984 6.00023Z"
                        fill="white"/>
                </svg>
            </button>
        </div>
        <div class="active__filters-item"
             v-if="this.filtersStore.rent[0] != this.filtersStore.rent_min || this.filtersStore.rent[1] != this.filtersStore.rent_max ">
            Прибутковість: {{ this.filtersStore.rent[0] }} - {{ this.filtersStore.rent[1] }}%
            <button @click="this.filtersStore.rent = [this.filtersStore.rent_min, this.filtersStore.rent_max]"
                    type="button" class="remove__btn">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                    <path
                        d="M6.52984 6.00023L9.64234 2.89242C9.70214 2.81956 9.7327 2.72706 9.72808 2.63291C9.72345 2.53877 9.68398 2.44971 9.61733 2.38306C9.55068 2.31641 9.46162 2.27693 9.36747 2.27231C9.27333 2.26768 9.18083 2.29824 9.10797 2.35804L6.00016 5.47054L2.89234 2.35804C2.81948 2.29824 2.72698 2.26768 2.63284 2.27231C2.53869 2.27693 2.44964 2.31641 2.38299 2.38306C2.31633 2.44971 2.27686 2.53877 2.27223 2.63291C2.26761 2.72706 2.29817 2.81956 2.35797 2.89242L5.47047 6.00023L2.35797 9.10804C2.28755 9.17914 2.24805 9.27516 2.24805 9.37523C2.24805 9.4753 2.28755 9.57132 2.35797 9.64242C2.42965 9.71173 2.52545 9.75047 2.62516 9.75047C2.72486 9.75047 2.82067 9.71173 2.89234 9.64242L6.00016 6.52992L9.10797 9.64242C9.17965 9.71173 9.27545 9.75047 9.37516 9.75047C9.47486 9.75047 9.57067 9.71173 9.64234 9.64242C9.71276 9.57132 9.75226 9.4753 9.75226 9.37523C9.75226 9.27516 9.71276 9.17914 9.64234 9.10804L6.52984 6.00023Z"
                        fill="white"/>
                </svg>
            </button>
        </div>
        <div class="active__filters-item"
             v-if="this.filtersStore.area[0] != this.filtersStore.area_min || this.filtersStore.area[1] != this.filtersStore.area_max ">
            Площа: {{ this.filtersStore.area[0] }} - {{ this.filtersStore.area[1] }} га
            <button @click="this.filtersStore.area = [this.filtersStore.area_min, this.filtersStore.area_max]"
                    type="button" class="remove__btn">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                    <path
                        d="M6.52984 6.00023L9.64234 2.89242C9.70214 2.81956 9.7327 2.72706 9.72808 2.63291C9.72345 2.53877 9.68398 2.44971 9.61733 2.38306C9.55068 2.31641 9.46162 2.27693 9.36747 2.27231C9.27333 2.26768 9.18083 2.29824 9.10797 2.35804L6.00016 5.47054L2.89234 2.35804C2.81948 2.29824 2.72698 2.26768 2.63284 2.27231C2.53869 2.27693 2.44964 2.31641 2.38299 2.38306C2.31633 2.44971 2.27686 2.53877 2.27223 2.63291C2.26761 2.72706 2.29817 2.81956 2.35797 2.89242L5.47047 6.00023L2.35797 9.10804C2.28755 9.17914 2.24805 9.27516 2.24805 9.37523C2.24805 9.4753 2.28755 9.57132 2.35797 9.64242C2.42965 9.71173 2.52545 9.75047 2.62516 9.75047C2.72486 9.75047 2.82067 9.71173 2.89234 9.64242L6.00016 6.52992L9.10797 9.64242C9.17965 9.71173 9.27545 9.75047 9.37516 9.75047C9.47486 9.75047 9.57067 9.71173 9.64234 9.64242C9.71276 9.57132 9.75226 9.4753 9.75226 9.37523C9.75226 9.27516 9.71276 9.17914 9.64234 9.10804L6.52984 6.00023Z"
                        fill="white"/>
                </svg>
            </button>
        </div>
        <div class="active__filters-item"
             v-if="this.filtersStore.land_price[0] != this.filtersStore.land_price_min || this.filtersStore.land_price[1] != this.filtersStore.land_price_max ">
            Ціна ділянки: {{ this.filtersStore.land_price[0] }} - {{ this.filtersStore.land_price[1] }} грн
            <button @click="this.filtersStore.land_price = [this.filtersStore.land_price_min, this.filtersStore.land_price_max]"
                    type="button" class="remove__btn">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                    <path
                        d="M6.52984 6.00023L9.64234 2.89242C9.70214 2.81956 9.7327 2.72706 9.72808 2.63291C9.72345 2.53877 9.68398 2.44971 9.61733 2.38306C9.55068 2.31641 9.46162 2.27693 9.36747 2.27231C9.27333 2.26768 9.18083 2.29824 9.10797 2.35804L6.00016 5.47054L2.89234 2.35804C2.81948 2.29824 2.72698 2.26768 2.63284 2.27231C2.53869 2.27693 2.44964 2.31641 2.38299 2.38306C2.31633 2.44971 2.27686 2.53877 2.27223 2.63291C2.26761 2.72706 2.29817 2.81956 2.35797 2.89242L5.47047 6.00023L2.35797 9.10804C2.28755 9.17914 2.24805 9.27516 2.24805 9.37523C2.24805 9.4753 2.28755 9.57132 2.35797 9.64242C2.42965 9.71173 2.52545 9.75047 2.62516 9.75047C2.72486 9.75047 2.82067 9.71173 2.89234 9.64242L6.00016 6.52992L9.10797 9.64242C9.17965 9.71173 9.27545 9.75047 9.37516 9.75047C9.47486 9.75047 9.57067 9.71173 9.64234 9.64242C9.71276 9.57132 9.75226 9.4753 9.75226 9.37523C9.75226 9.27516 9.71276 9.17914 9.64234 9.10804L6.52984 6.00023Z"
                        fill="white"/>
                </svg>
            </button>
        </div>
        <div class="active__filters-item"
             v-if="this.filtersStore.price_per_hectare[0] != this.filtersStore.price_per_hectare_min || this.filtersStore.price_per_hectare[1] != this.filtersStore.price_per_hectare_max ">
            Ціна за 1 га: {{ this.filtersStore.price_per_hectare[0] }} - {{ this.filtersStore.price_per_hectare[1] }} грн
            <button @click="this.filtersStore.price_per_hectare = [this.filtersStore.price_per_hectare_min, this.filtersStore.price_per_hectare_max]"
                    type="button" class="remove__btn">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                    <path
                        d="M6.52984 6.00023L9.64234 2.89242C9.70214 2.81956 9.7327 2.72706 9.72808 2.63291C9.72345 2.53877 9.68398 2.44971 9.61733 2.38306C9.55068 2.31641 9.46162 2.27693 9.36747 2.27231C9.27333 2.26768 9.18083 2.29824 9.10797 2.35804L6.00016 5.47054L2.89234 2.35804C2.81948 2.29824 2.72698 2.26768 2.63284 2.27231C2.53869 2.27693 2.44964 2.31641 2.38299 2.38306C2.31633 2.44971 2.27686 2.53877 2.27223 2.63291C2.26761 2.72706 2.29817 2.81956 2.35797 2.89242L5.47047 6.00023L2.35797 9.10804C2.28755 9.17914 2.24805 9.27516 2.24805 9.37523C2.24805 9.4753 2.28755 9.57132 2.35797 9.64242C2.42965 9.71173 2.52545 9.75047 2.62516 9.75047C2.72486 9.75047 2.82067 9.71173 2.89234 9.64242L6.00016 6.52992L9.10797 9.64242C9.17965 9.71173 9.27545 9.75047 9.37516 9.75047C9.47486 9.75047 9.57067 9.71173 9.64234 9.64242C9.71276 9.57132 9.75226 9.4753 9.75226 9.37523C9.75226 9.27516 9.71276 9.17914 9.64234 9.10804L6.52984 6.00023Z"
                        fill="white"/>
                </svg>
            </button>
        </div>
    </div>
</template>

<style scoped lang="scss">

</style>
