<script>
import {log} from "uikit/src/js/api/log.js";

export default {
    name: "pagination-component",
    props:{
        per_page: {
            default: 10
        },
        pagination:{
            links:[]
        },
    },
    methods:{
        getUrl(url){
            return window.location.href.split('?')[0]+'?'+url.split('?')[1]
        },
        paginate(url){
          this.$emit('paginate', this.getQueryParams(url, 'page'))
        },
        getQueryParams(url, paramName) {
            if (url) {
                const searchParams = new URLSearchParams(url.split('?')[1]);
                if (searchParams.has(paramName)) {
                    return searchParams.get(paramName)
                }
            }
            return null;
        },
    }
}
</script>

<template>
    <div class="pagination__block">

        <ul class="uk-pagination uk-flex-center" uk-margin v-if="pagination.links">
            <li v-if="pagination?.prev_page_url">
                <a :href="getUrl(pagination.prev_page_url)" @click.prevent="paginate(pagination.prev_page_url)">
                    <span uk-pagination-previous></span>
                </a>
            </li>
            <li v-else>
                <span>

                </span>
            </li>

            <li v-for="(link, idx) in pagination.links" :key="link.url" :class="{'uk-active':link.active}">
                <a :href="getUrl(link.url)" @click.prevent="paginate(link.url)"  v-if="!link.active">{{link.label}}</a>
                <span v-else>{{ link.label }}</span>
            </li>
            <li v-if="pagination?.next_page_url"><a :href="getUrl(pagination.next_page_url)" @click.prevent="paginate(pagination.next_page_url)"><span uk-pagination-next></span></a></li>
            <li v-else>
                <span>

                </span>
            </li>
        </ul>
    </div>

</template>

<style lang="scss">
.pagination__block{
    .uk-pagination{
        gap: 4px;
        li{
            text-align: center;
            &.uk-first-column{
                margin-right: 15px;
            }
            &.uk-first-column{
                margin-right: 15px;
            }
            &:last-child{
                margin-left: 15px;
            }

            a, &.uk-active span, &.uk-disabled span{
                height: 30px;
                border: 1px solid rgba(36, 36, 36, 0.20);
                border-radius:4px;
                color: #444 !important;
                font-family: Montserrat, sans-serif;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                aspect-ratio: 1/1;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            &.uk-active{
                span{
                    color: #FFF !important;
                    font-size: 12px;
                    background: $green;
                    border: 1px solid $green;
                    border-radius:4px;
                    height: 30px;
                }
            }
            &.uk-disabled span{
                aspect-ratio: initial;
                padding-left: 18px;
                padding-right: 18px;
            }
        }
    }
}

</style>
