<script>
import {BeatLoader} from "vue3-spinner";

export default {
    name: "spinner-for-btn",
    components: {
        BeatLoader
    },
    props: {
        is_loading: {
            default: false,
            type: Boolean
        }
    }
}
</script>

<template>
    <BeatLoader
        :loading="is_loading"
        color="#FFCE1F"
        class="spinner-item"

    />


</template>

<style scoped lang="scss">
.spinner-item {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    z-index: 1000;
    align-items: center;
    justify-content: center;
    top: 0;
    backdrop-filter: none;
}
</style>
