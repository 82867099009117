<script>
import VueMultiselect from 'vue-multiselect'

export default {
    name: "single-select-component",
    emits: ['update:modelValue'],
    props: ['modelValue', 'data'],
    components: {
        VueMultiselect
    },

    computed: {
        value: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        }
    }
}
</script>

<template>
    <VueMultiselect
        class="multiselect__component"
        v-model="value"
        :options="data"
        :multiple="false"
        label="title"
        track-by="title"
        placeholder="Пошук"
        :showLabels="false"
    >
        <template #option="{option}">
            <div class="multiselect__option-custom">
                {{option.title}}
            </div>

        </template>
        <template #noResult>Нічого не знайдено</template>
        <template #placeholder>----</template>
        <template #tag="{option}">
            <span>{{ option.title }}</span>
        </template>
    </VueMultiselect>
</template>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss">
 @import '../../scss/components/multiselect-styles';
</style>
