<script>

import Simplebar from 'simplebar-vue';
import 'simplebar-vue/dist/simplebar.min.css';
import Slider from '@vueform/slider'
import Icon from "@/components/icon.vue";
import MultiselectComponent from "@/components/partial/multiselect-component.vue";
import SingleSelectComponent from "@/components/partial/single-select-component.vue";
import MapListFilter from "@/components/mapList/map-list-filter.vue";
import vueDebounce from "vue-debounce";
import {useFiltersStore} from "@/store/filter.js";
import UIkit from "uikit";
import FiltersResult from "@/components/partial/filters-result.vue";

export default {
    name: "map-filter",
    props:{
      total_areas:{
          default: 0
      }
    },
    directives: {
        debounce: vueDebounce({lock: true})
    },
    setup() {
        const filtersStore = useFiltersStore()
        return {filtersStore}
    },

    data() {
        return {
            coll_count: 23,
            is_mobile: false,

        }
    },
    mounted() {
        window.addEventListener('resize', this.setMobile)
        this.setMobile();
    },
    components: {
        FiltersResult,
        MapListFilter,
        SingleSelectComponent,
        MultiselectComponent,
        Icon,
        Simplebar,
        Slider,
    },
    methods: {
        emitUpdateAreas(){
          this.$emit('update-areas')
        },
        setMobile() {
            if (window.innerWidth < 992) {
                this.is_mobile = true;
                this.$refs.map_filter.setAttribute("uk-offcanvas", 'overlay: true');
                this.$refs.map_filter.querySelector('.map__filter-wrapper').classList.add('uk-offcanvas-bar');
            } else {
                this.$refs.map_filter.removeAttribute("uk-offcanvas");
                this.$refs.map_filter.classList.remove("uk-offcanvas");
                this.$refs.map_filter.querySelector('.map__filter-wrapper').classList.remove('uk-offcanvas-bar');
                this.is_mobile = false;
            }
        },
        setRange(data){
            let collist = event.target.closest('.form__row').querySelectorAll('.column');
            let activeCountMax =  Math.floor((this.coll_count*data[1])/this.profitability_max)
            let activeCountMin =  Math.round((this.coll_count*data[0])/this.profitability_max)
            console.log(activeCountMin,activeCountMax)
            collist.forEach((el, idx)=>{

                if( activeCountMin<= idx && idx <= activeCountMax ){
                    el.classList.add("active")
                } else{
                    el.classList.remove("active")
                }
            })
        },
        openFilter() {
            this.$refs.map_filter.classList.toggle('open__filter')
        },
        resetFilters(){
            this.filtersStore.resetFilters()

        },
        searchHandler(query){
            this.$emit('search', query)
        }

    },
}
</script>

<template>
    <div class="map__filter" ref="map_filter" id="map_filter">
        <!--        "uk-offcanvas-bar" add for mobile-->
        <div class="map__filter-wrapper " ref="canvas_bar">
            <div class="filter__head">
                <div class="title__count">
                    <div class="title">
                        Фільтр
                    </div>
                    <div class="count">
                        Знайдено ділянок: <b>{{total_areas}}</b>
                    </div>
                </div>
                <div class="clear__filter-btn" @click="resetFilters">
                    <icon name="clear-filter"/>
                </div>
                <div class="filter__btns">
                    <div class="open__filter-btn" @click="openFilter">
                        <icon name="arr-top"/>
                    </div>
                    <button class="uk-offcanvas-close" style="display: none" type="button">
                        <icon name="close"/>
                    </button>
                </div>
            </div>
            <Simplebar data-simplebar-auto-hide="false">
                <FiltersResult/>
                <div class="search__wrapper">
                    <div class="search__icon">
                        <icon name="search"/>
                    </div>
                    <input type="text" class="search__input"  v-debounce:400ms="searchHandler" placeholder="Введіть кадастровий номер або ID ">
                </div>
                <MapListFilter/>
                <div class="btns__wrapper" style="display: none;">
                    <a href="" class="mobile__filter-action-btn apply uk-offcanvas-close">Фільтрувати</a>
                    <a href="" class="mobile__filter-action-btn cancel disabled uk-offcanvas-close" @click.prevent="resetFilters">Скинути</a>
                </div>
            </Simplebar>

        </div>

    </div>
</template>
<style src="@vueform/slider/themes/default.css"></style>

<style lang="scss">
.map__filter {
    position: absolute;
    left: 100px;
    top: 20px;
    .map__list-filter {
        margin-top: 25px;
        .row__wrapper.col__2{
            grid-template-columns: 1fr;
        }
        .range__slider-wrapper{
            padding: 0 10px;
        }
    }

    .active__filters{

        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px ;
        background-color: white;


        &:has(.active__filters-item){
            margin-top: 25px;
        }


        .active__filters-item{
            display: flex;
            justify-content: space-between;
            gap: 8px;
            color: $black;
            text-align: left;
            font-size: 11px;
            font-style: normal;
            font-weight: 600;
            line-height: 140%; /* 15.4px */
            padding: 5px;
            border-radius: 5px;
            background-color: $grey;
            align-items: center;


            &.regions{
                grid-column-start: 1;
                grid-column-end: 3;
                grid-row-start: 1;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;

                .title{
                    text-align: left;
                }
                .region__wrapper{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 10px;
                }
            }
            .remove__btn{
                width: 18px;
                height: 18px;
                min-width: 18px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 5px;
                background: $red;
            }
        }

    }

    &.open__filter {


        .map__filter-wrapper {
            height: fit-content;
            max-height: 690px;

            .simplebar-wrapper {
                max-height: 590px;
                overflow-x: visible;
            }


            .filters__form {
                opacity: 1;
                visibility: visible;
            }

            .filter__head {
                grid-template-columns: 1fr 50px !important;
                .clear__filter-btn{
                    display: none !important;
                }

                .open__filter-btn {
                    background-color: $green;

                    svg {
                        color: $white;
                        transform: rotateX(0);
                    }
                }
            }
        }
    }

    .map__filter-wrapper {
        max-height: 75px;
        overflow: hidden;
        width: 410px;
        border-radius: 15px;
        background: #FFF;
        box-shadow: 2px 5px 15px 0px rgba(0, 0, 0, 0.04);
        padding: 10px 20px;
        transition: 0.5s;

        .simplebar-track {
            right: -16px;
        }

        .simplebar-track.simplebar-vertical {

            width: 8px;
        }
        .search__wrapper {
            position: relative;
            margin-top: 25px;
            .search__icon {
                color: $black;
                position: absolute;
                left: 20px;
                top: 50%;
                transform: translateY(-50%);
            }

            .search__input {
                padding: 15px 15px 15px 46px;
                height: 45px;
                border-radius: 10px;
                border: 1px solid rgba(36, 36, 36, 0.20);
                width: 100%;
                color: rgba(36, 36, 36, 0.50);
                font-family: Montserrat, sans-serif;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 100%; /* 12px */
            }
        }
        &:has(.active__filters-item){
            .filter__head{
                grid-template-columns: 1fr 50px 50px;
                grid-gap: 10px;
                .clear__filter-btn{
                    display: flex;
                }
            }
        }

        .filter__head {
            display: grid;
            grid-template-columns: 1fr 50px;
            background: white;

            .title__count {
                align-self: center;

                .title {
                    color: $black;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 100%;
                    margin-bottom: 10px;
                }

                .count {
                    color: $dark-gray;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 100%; /* 12px */
                    b {
                        font-size: 14px;
                        font-weight: 700;
                        color: $green;
                    }
                }
            }
                .clear__filter-btn{
                    width: 50px;
                    height: 50px;
                    display: none;
                    justify-content: center;
                    align-items: center;
                    border-radius: 5px;
                    background: $red;
                    cursor: pointer;
                    color: white;
                }
            .open__filter-btn {
                width: 50px;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 5px;
                background: $light-blue;
                cursor: pointer;

                svg {
                    color: $black;
                    transition: 0.3s;
                    transform: rotateX(180deg);
                }
            }
        }


    }

}

@media screen and (max-width: $breakpoint-df) {
    .map__page{
        .map__filter{
            z-index: 51;
        }
    }
    .map__filter {
        position: fixed;
        top: 0;
        left: 0;


        .map__list-filter{
            margin-bottom: 25px;
        }
        &.uk-open {
            .map__filter-wrapper {
                left: 0;
            }
        }
        .btns__wrapper {
            display: flex !important;
            flex-direction: column;
            gap: 10px;
            margin-top: auto;
            .mobile__filter-action-btn {
                @include black-btn(55px, 100%, 12px, 12px);
                font-size: 14px;
                position: relative;
                right: initial;
                top: initial;

                &.cancel {
                    @include transparent-btn(55px, 100%, 12px, 12px);
                    font-size: 14px;
                }

                &.disabled {
                    border-color: rgba(36, 36, 36, 0.50);
                    color: rgba(36, 36, 36, 0.50) !important;

                }
            }

        }
        .map__filter-wrapper {
            left: -375px;
            width: 375px;
            max-height: 100% !important;
            height: 100%;
            border-radius: 0;

            .filter__head {
                margin-bottom: 20px;
                .clear__filter-btn{
                    display: none !important;
                }

                .uk-offcanvas-close {
                    background: transparent;
                    display: block !important;
                }

                .open__filter-btn {
                    display: none;
                }

            }

            .simplebar-wrapper {
                max-height: calc(100vh - 90px) !important;
            }

            .filters__form {
                opacity: 1;
                visibility: visible;

                .radio__btns {
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 10px;
                }
            }
        }

    }

}
@media (max-width: $breakpoint-xs) {
    .map__filter .map__filter-wrapper{
        width: 100%;
    }
}
</style>
